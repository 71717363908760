import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { style, GroupButton, Title } from "./style";

import { withStyles } from "@material-ui/core/styles";
import Navbar from "../../../../components/navbar";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Typography } from "@material-ui/core";
import placeholder from "../../../../assets/images/placeholder.jpg";
import iconWallet from "../../../../assets/icons/wallet.svg";
import iconCoins from "../../../../assets/icons/coins.svg";
import iconHistory from "../../../../assets/icons/history.svg";
import iconAdd from "../../../../assets/icons/add.svg";
import iconWithdraw from "../../../../assets/icons/withdraw.svg";
import { MenuMobile, SeeAll } from "../../../../components";
import { getWalletInfo } from "../../../../services/Reseller/reseller.service";
import Loading from "../../../../components/loading";
import { useAuthContext } from "../../../../contexts/AuthContextProvider";
import { formatRupiah } from "../../../../utils/currencyFormat";
import {
  ModalTopup,
  ModalWithdraw,
  ProductSlider,
} from "../../../../containers";
import { GET_WISHLIST } from "../../../../services/wishlist.service";
import { isEmpty } from "lodash";
import swal from "sweetalert";

const Mobile = withStyles(style)((props) => {
  const { classes } = props;
  const [walletInfo, setWalletInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuthContext();
  const [isOpenModal, setIsOpenModal] = useState({
    topup: false,
    withdraw: false,
  });
  const [dataWishlist, setDataWishlist] = useState([]);
  const [isRefetch, setIsRefetch] = useState(false);
  const [isNotOrdinarySeller, setisNotOrdinarySeller] = useState()

  // check is not ordinary seller
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("USER_DATA"))) {
      if (JSON.parse(localStorage.getItem("USER_DATA")).level.name === 'Seller') {
        setisNotOrdinarySeller(false)
      }else{
        setisNotOrdinarySeller(true)
      }
    }
  }, [])

  const handleClick = (type, value) => {
    setIsOpenModal({
      ...isOpenModal,
      [type]: value,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      const walletInfo = await getWalletInfo();
      const getWishlist = await GET_WISHLIST();
      setWalletInfo(walletInfo.data);
      setDataWishlist(getWishlist.data);
      setIsLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const getWishlist = await GET_WISHLIST();
      setDataWishlist(getWishlist.data);
      setIsRefetch(false);
    };
    fetch();
  }, [isRefetch]);

  const handleImgError = (event) => {
    return (event.target.src = placeholder);
  };

  const handleChangeRekening = () => {
    if (isNotOrdinarySeller) {
      props.history.push("/user/my-profile/edit-rekening")
    }else{
      swal({
        title: "Akses atur rekening ditolak",
        text: "Fitur ini hanya dapat digunakan oleh pengguna Super Seller",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willUpgrade) => {
        if (willUpgrade) {
          props.history.push('/upgrade-reseller')
        }
      });
    }
  }

  return (
    <Fragment>
      <Navbar variant={"primary"} />
      {isLoading ? (
        <Loading />
      ) : (
        <Grid item xs={12} style={{ padding: 16, background: "#FFF" }}>
          <div className={classes.card}>
            {user === null ? (
              <Skeleton width={75} height={75} variant="circle" />
            ) : (
              <img
                src={user.avatar || placeholder}
                alt={`profile of ${user.name}`}
                className={classes.photoProfile}
                onError={handleImgError}
              />
            )}
            <div style={{ margin: "auto 0 auto 28px", alignItems: "center" }}>
              <div>
                {user === null ? (
                  <Skeleton variant="text" width={88} height={24} />
                ) : (
                  <Typography className={classes.nameStore}>
                    {user.name}
                  </Typography>
                )}

                <div className={classes.statusMember}>Member Regular</div>
                <div
                  className={classes.countFollow}
                  style={{ marginTop: "8px" }}
                >
                  <span>{user?.following_count ?? 0} Mengikuti</span>
                  {/* <span style={{ marginLeft: "8px" }}>2.6RB Pengikut</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.cardYellow}>
            <div className={classes.cardDompetCoin}>
              <div className={classes.totalDompetCoin} style={{ width: "50%" }}>
                <span className={classes.titleDompetCoin}>
                  <img src={iconWallet} alt="icon" className={classes.icon} />
                  DompetJualan
                </span>
                <span>Rp {formatRupiah(user.wallets)}</span>
              </div>
              <div
                className={classes.totalDompetCoin}
                style={{
                  width: "50%",
                  paddingLeft: 16,
                  borderLeft: "1px solid #A3A3A3",
                }}
              >
                <span className={classes.titleDompetCoin}>
                  <img src={iconCoins} alt="icon" className={classes.icon} />
                  Reward Koin
                </span>
                <span>{user?.coins ?? 0} Koin</span>
              </div>
            </div>
            <GroupButton>
              <button onClick={() => props.history.push("/history")}>
                <img src={iconHistory} alt="icon" className={classes.icon} />
                Riwayat
              </button>
              <button onClick={() => handleClick("topup", !isOpenModal.topup)}>
                <img src={iconAdd} alt="icon" className={classes.icon} />
                Isi Saldo
              </button>
              <button
                onClick={() => handleClick("withdraw", !isOpenModal.withdraw)}
              >
                <img src={iconWithdraw} alt="icon" className={classes.icon} />
                Cairkan
              </button>
            </GroupButton>
          </div>

          {/* <div>
          <Title>Penjualanmu</Title>
          <StatisticSelling>
            <div className="sub">
              <span>Berhasil Terjual</span>
              <span>Rp. 3.451.000</span>
              <span>dari 128 Pcs</span>
            </div>
            <div className="sub">
              <span>Dibatalkan/Refund</span>
              <span>Rp. 900.000</span>
              <span>dari 8 pcs</span>
            </div>
            <div className="total">
              <span>Total Keuntungan :</span>
              <span>Rp. 1.234.000</span>
            </div>
          </StatisticSelling>
          
        </div> */}

          <div>
            {isEmpty(dataWishlist) ? (
              <MenuMobile
                title="Wishlist"
                desc="Lihat Produk yang sudah kamu wishlist"
                style={{ marginTop: 16 }}
              />
            ) : (
              <>
                <SeeAll onClick={() => props.history.push("/wishlist")}>
                  <Title>Wishlist</Title>
                </SeeAll>
                <ProductSlider
                  type="wishlist"
                  dataWishlist={dataWishlist}
                  setIsRefetch={setIsRefetch}
                  {...props}
                />
              </>
            )}
            <MenuMobile
              title="Pesanan saya"
              desc="Lihat Produk yang sudah kamu pesan"
              style={{ marginTop: 16 }}
              onClick={() => props.history.push("/orders")}
            />

            <Title>Pengaturan Akun</Title>
            <MenuMobile
              title="Edit Profil"
              desc="Mengatur  ulang data profilmu"
              style={{ marginTop: 16 }}
              onClick={() =>
                props.history.push("/user/my-profile/edit-profile")
              }
            />
            <MenuMobile
              title="Edit Tokoku"
              desc="Mengatur  ulang data Tokoku"
              onClick={() =>
                props.history.push("/user/my-profile/edit-toko")
              }
            />
            <MenuMobile
              title="Atur Rekening"
              desc="Mengatur rekening untuk transaksimu"
              onClick={handleChangeRekening}
            />
          </div>
        </Grid>
      )}
      {isOpenModal.withdraw && (
        <ModalWithdraw
          isOpen={isOpenModal.withdraw}
          walletInfo={walletInfo}
          handleClose={() => handleClick("withdraw", !isOpenModal.withdraw)}
        />
      )}

      {isOpenModal.topup && (
        <ModalTopup
          isOpen={isOpenModal.topup}
          handleClose={() => handleClick("topup", !isOpenModal.topup)}
          {...props}
        />
      )}
    </Fragment>
  );
});

export default withRouter(Mobile);

import React, { Fragment, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";
import Navbar from "../../../components/navbar";
import { Container, Grid, Button, Typography, Drawer } from "@material-ui/core";
import FilterIcon from "../../../assets/icons/heroicons-outline_filter.svg";
import FilterIconLeft from "../../../assets/icons/bi_filter-left.svg";
import CardProduct from "../../../components/CardProduct";
import { getProductData, getProvinces } from "../../../services/axios";
import Pagination from "@material-ui/lab/Pagination";
import { withRouter } from "react-router-dom";
import Loading from "../../../components/loading";
import Filter from "./components/filter";
import Sort from "./components/sort";

const Mobile = withStyles(style)((props) => {
  const { classes } = props;
  const [openFilter, setOpenFilter] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const category = props.match.params.category;
  const category_id = category.slice(category.search("cat.")).slice(4);
  const [data, setData] = useState(null);
  const [lokasi, setLokasi] = useState([]);
  const [listProvince, setListProvince] = React.useState(null);
  const category_name = category
    .replace("-", " ")
    .slice(0, category.indexOf("-cat."));

  useEffect(() => {
    const fetch = async () => {
      const response = await getProductData(
        `?filter[category.id]=${category_id}`
      );
      const responseProvince = await getProvinces();
      setData(response);
      setListProvince(responseProvince.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    console.log(lokasi);
  }, [lokasi]);

  const handleFilter = () => {
    const fetch = async () => {
      const response = await getProductData(
        `?filter[category.id]=${category_id}&filter[supplier.province_id]=${lokasi}`
      );
      setData(response);
    };
    fetch();
  };

  const handleResetFilter = () => {
    setLokasi([]);
    const fetch = async () => {
      const response = await getProductData(
        `?filter[category.id]=${category_id}`
      );
      setData(response);
    };
    fetch();
  };

  const handleChangePage = async (index) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const response = await getProductData(
      `?filter[category.id]=${category_id}&page=${index}`
    );
    setData(response);
  };

  const handleFilterLokasi = (value) => {
    if (lokasi[lokasi.indexOf(value)] === value) {
      setLokasi(lokasi.filter((loc) => loc !== value));
    } else {
      setLokasi([...lokasi, value]);
    }
  };

  const handleSort = (value) => {
    if (value === "Termurah") {
      const fetch = async () => {
        const response = await getProductData(
          `?filter[category.id]=${category_id}&filter[supplier.province_id]=${lokasi}&sort=-ref_price`
        );
        setData(response);
      };
      fetch();
    } else if (value === "Termahal") {
      const fetch = async () => {
        const response = await getProductData(
          `?filter[category.id]=${category_id}&filter[supplier.province_id]=${lokasi}&sort=ref_price`
        );
        setData(response);
      };
      fetch();
    } else if (value === "Terbaru") {
      const fetch = async () => {
        const response = await getProductData(
          `?filter[category.id]=${category_id}&filter[supplier.province_id]=${lokasi}&filter[from_newest]=1`
        );
        setData(response);
      };
      fetch();
    }
  };

  return (
    <Fragment>
      <Navbar variant="primary" />
      <Container maxWidth="lg">
        {!data ? (
          <Loading />
        ) : (
          <Fragment>
            <h1 style={{ textAlign: "center", paddingTop: 20 }}>
              {category_name}
            </h1>
            {data.data.length === 0 ? (
              <Typography style={{ textAlign: "center", paddingTop: 30 }}>
                Data tidak ditemukan.
              </Typography>
            ) : (
              <Fragment>
                <Grid container spacing={2} style={{ paddingTop: 30 }}>
                  {data.data.map((res, index) => (
                    <Grid item xs={6} key={index}>
                      <CardProduct variant="reseller" data={res} />
                    </Grid>
                  ))}
                </Grid>

                <div className={classes.pagination}>
                  <Pagination
                    count={data.meta.last_page}
                    siblingCount={data.meta.current_page}
                    variant="outlined"
                    size="small"
                    shape="rounded"
                    color="primary"
                    onChange={(event, index) => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      handleChangePage(index);
                    }}
                  />
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </Container>
      <Container maxWidth="lg" className={classes.actionFilter}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              startIcon={<img src={FilterIcon} alt={"icon"} />}
              onClick={() => setOpenFilter(true)}
            >
              <b>Filter</b>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              startIcon={<img src={FilterIconLeft} alt={"icon"} />}
              onClick={() => setOpenSort(true)}
            >
              <b>Urutkan</b>
            </Button>
          </Grid>
        </Grid>
      </Container>

      <Drawer
        anchor="left"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <Filter
          onClose={() => setOpenFilter(false)}
          listProvince={listProvince}
          lokasi={lokasi}
          handleFilterLokasi={handleFilterLokasi}
          applyFilter={() => {
            handleFilter();
            setOpenFilter(false);
          }}
          resetFilter={() => {
            handleResetFilter();
            setOpenFilter(false);
          }}
        />
      </Drawer>

      <Drawer anchor="right" open={openSort} onClose={() => setOpenSort(false)}>
        <Sort onClose={() => setOpenSort(false)} handleSort={handleSort} />
      </Drawer>
    </Fragment>
  );
});

export default withRouter(Mobile);

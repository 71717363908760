import React from "react";

import { Grid, Typography, Card, Chip, Avatar, Box } from "@material-ui/core";

import { numberSeparator } from "../../../utils/numberSeparator";

const Forwarded = (props) => {
  const { order } = props;

  return (
    <Card key={order.id} style={{ marginBottom: 16 }} variant="outlined">
      <Box
        bgcolor="#3fbfc5"
        width="fit-content"
        color="#fff"
        fontWeight={600}
        p={1}
        px={2}
      >
        Order ID: #{order.id}
      </Box>
      <Box p={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridGap={6}>
            <Avatar
              src={order.supplier.avatar_url}
              style={{ width: 36, height: 36 }}
            />
            <Typography>{order.supplier.name}</Typography>
          </Box>
          <Typography>
            Status: {order.histories[order.histories.length - 1].message}
          </Typography>
        </Box>

        <Box height={2} mt={1} mb={2} bgcolor="#F7F7F7" />
        {order.items.map(({ id, sku, reseller_price_idr, qty }) => {
          if (sku) {
            return (
              <Box mb={2} key={id}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography>{sku.product.name}</Typography>
                    {sku.properties && (
                      <Box mt={1} mb={2}>
                        <Chip
                          variant="outlined"
                          label={sku.properties.color}
                          disabled
                          size="small"
                        />{" "}
                        <Chip
                          variant="outlined"
                          label={sku.properties.material}
                          disabled
                          size="small"
                        />{" "}
                        <Chip
                          variant="outlined"
                          label={sku.properties.size}
                          disabled
                          size="small"
                        />
                      </Box>
                    )}
                    <Typography color="primary" component="span" variant="h6">
                      Rp.{numberSeparator(reseller_price_idr || 0)}
                    </Typography>
                    {"   x   "}
                    <Typography
                      component="span"
                      color="textSecondary"
                      variant="h6"
                    >
                      {qty}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Avatar
                      src={sku.image_url}
                      style={{ height: 64 }}
                      variant="square"
                    />
                  </Grid>
                </Grid>
              </Box>
            );
          }
        })}
      </Box>
      <Box mb={2} height={2} bgcolor="#F7F7F7" />
      <Box display="flex" alignItems="flex-end" flexDirection="column" px={2}>
        <Typography variant="caption">Jumlah harus dibayar</Typography>
        <Typography variant="h6" style={{ color: "red" }}>
          Rp.{numberSeparator(order.payment.amount || 0)}
        </Typography>
      </Box>
    </Card>
  );
};

export default Forwarded;

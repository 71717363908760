import { axiosInstance } from "../config/fetchAxios";

export const getOrders = async (params) => {
  const response = axiosInstance.get(`/api/v1/orders`, { params });
  return response;
};

export const getTracking = async (body) => {
  const response = axiosInstance.post(
    `/api/v1/support/shipping/tracking`,
    body
  );
  return response;
};

export const getPayments = async (params) => {
  const response = axiosInstance.get(
    `/api/v2/payments?filter[status]=checkout&filter[payment_type]=vendorbank`,
    { params }
  );
  return response;
};

export const getPaymentsCod = async (params) => {
  const response = axiosInstance.get(
    `/api/v2/payments`,
    { params }
  );
  return response;
};

export const getPaymentsCustomer = async (store, token) => {
  const response = axiosInstance.get(
    `/api/v1/storefronts/${store}/payments/${token}`,
  );
  return response;
};

export const cancelOrder = async (id) => {
  const response = axiosInstance.delete(`/api/v2/payments/${id}`);
  return response;
};

export const confirmPayment = async (body, id) => {
  const response = axiosInstance.post(`/api/v2/confirm-payment/${id}`, body);
  return response;
};

export const stylesMobile = (theme) => ({
  titleProduct: {
    fontWeight: "bold",
    marginBottom: 15,
    marginTop: 20,
    fontSize: "1.1rem",
  },
  tagProduct: {
    color: "#555555",
    opacity: 0.65,
    paddingBottom: 5,
    fontSize: 14,
  },
  blackColour: {
    color: "#555555",
    fontWeight: 600,
  },
  yellowColour: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  redColour: {
    color: "#ff6564",
    fontWeight: 600,
  },
  storeImage: {
    height: 70,
    width: 70,
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    borderRadius: "50%",
  },
  storeCity: {
    color: "#555555",
    opacity: 0.65,
    fontSize: "0.8rem",
  },
  navigateNext: {
    background: theme.palette.primary.main,
    borderRadius: 4,
    marginLeft: -40,
    zIndex: 9,
    color: "#ffffff",
    transition: "0.5s !important",
    "&:hover": {
      background: "#F2F4F8",
      color: "#555555",
      transition: "0.5s",
      opacity: 0.35,
    },
  },
  navigateBefore: {
    background: theme.palette.primary.main,
    borderRadius: 4,
    position: "absolute",
    marginLeft: 15,
    zIndex: 9,
    color: "#ffffff",
    transition: "0.5s !important",
    "&:hover": {
      background: "#F2F4F8",
      color: "#555555",
      transition: "0.5s",
      opacity: 0.35,
    },
  },
  loading: {
    height: window.screen.height * 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customerCart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    height: 60,
    width: 60,
    bottom: 0,
    right: 0,
    marginBottom: 90,
    marginRight: 10,
    cursor: "pointer",
  },
  superSellerPrice: {
    fontWeight: 'bold',
    border: '1px solid #ffffff',
    borderRadius: 5,
    marginLeft: 5,
    padding: '0 5px'
  },
  buttonResellerPremium: {
    backgroundColor: "#29C0C7",
    color: "#ffffff",
    padding: 10,
    justifyContent: 'space-around',
    '&:hover': {
      backgroundColor: "#1b8388"
    }
  }
});

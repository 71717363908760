const style = (theme) => ({
  icon: {
    height: 25,
    width: 25,
    backgroundColor: "grey",
  },
  iconClicked: {
    height: 25,
    width: 25,
    backgroundColor: theme.palette.primary.main,
  },
  iconTab: {
    paddingRight: 5,
    marginBottom: "0 !important",
  },
  input: {
    padding: 10,
  },
  card: {
    backgroundColor: "#f5f5f5",
    padding: "40px 30px",
    width: "100%",
    display: "flex",
  },
  cardGrid: {
    height: 120,
    display: "flex",
    alignItems: "center",
  },
  photoProfile: {
    height: 120,
    width: 120,
    borderRadius: 16,
  },
  title: {
    fontWeight: 600,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    paddingTop: 10,
    height: 55,
  },
  priceDiscount: {
    fontSize: "0.9rem",
    color: "#555555",
    paddingTop: 10,
    display: "flex",
    alignItems: "center",
  },
  button2: {
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: "5px 0",
    width: "100%",
    textTransform: "none",
  },
  pagination: {
    paddingTop: 25,
    display: "flex",
    justifyContent: "center",
  },
});

export default style;

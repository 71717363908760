const styles = (theme) => ({
  bg: {
    background: "#f5f5f5",
    borderRadius: 8,
  },
  detailBelanja: {
    padding: "20px 35px",
  },
  content: {
    padding: "10px 35px",
  },
  container: {
    paddingTop: 60,
    marginBottom: 100,
  },
  storeText: {
    display: "flex",
  },
  image: {
    objectFit: "cover",
    borderRadius: 4,
  },
  kurirBg: {
    background: "rgba(253, 205, 15, 0.15)",
    padding: "25px 35px",
    display: "flex",
  },
  codBg: {
    background: "#F5F5F5",
    padding: "10px 35px",
    display: "flex",
    alignItems: 'center'
  },
  formControl: {
    width: "90%",
  },
  catatanBg: {
    background: "#f5f5f5",
    padding: "25px 35px",
    display: "flex",
  },
  ubahAlamat: {
    borderBottomStyle: "dotted",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    width: "fit-content",
    paddingTop: 20,
    marginBottom: 30,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  detailContainer: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
  textField: {
    '& p':{
      color:'red',
    },
  },
});

export default styles;

import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { stylesMobile } from "./style";
import formatCash from "../../../utils/formatCash";
import {
  Breadcrumbs,
  Container,
  Typography,
  Link,
  Grid,
  Drawer,
  Badge,
  Button,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavbarProductMobile from "../../../components/Mobile/NavbarProduct";
import Carousel from "../../../components/carousel";
import DividerMobile from "../../../components/divider-mobile";
import CekOngkir from "./components/cek-ongkir";
import Variant from "./components/variant";
import "../style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loading from "../../../components/loading";
import {
  getProductData,
  getSupplier,
  getStorefront,
  getStorefrontProducts,
  upgradeAccount,
  isLoggedIn,
} from "../../../services/axios";
import { numberSeparator } from "../../../utils/numberSeparator";
import { generateDate } from "../../../utils/generateDate";
import { newLineRevert } from "../../../utils/newLine";
import swal from "@sweetalert/with-react";
import { withRouter } from "react-router-dom";
import product_recomendation from "../../../utils/productsRecomendation";
import BottomNavbar from "./components/BottomNav";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ReactComponent as PremiumIcon } from "../../../assets/icons/icon_premium.svg";
import UpgradePremiumDialog from "../../../components/upgrade-premium-dialog";
import PopupDeeplink from "../../../components/Mobile/DeeplinkPopup";

const Mobile = withStyles(stylesMobile)((props) => {
  const { classes, match, history } = props;
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;
  const isPremiumMember =
    JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;
  const [loading, setLoading] = useState(true);
  const [currentVariant, setCurrentVariant] = useState("");
  const [data, setData] = useState("");
  const [dataSupplier, setDataSupplier] = useState();
  const [supplierStoreFront, setsupplierStoreFront] = useState()
  const [isReadMore, setIsReadMore] = useState(false);
  const [openCekOngkir, setOpenCekOngkir] = useState(false);
  const [openUpgradePremium, setOpenUpgradePremium] = useState(false);
  const [tokenUpgradePremium, setTokenUpgradePremium] = useState("");
  const [openVariant, setOpenVariant] = useState(false);
  const [sliderProduct, setSliderProduct] = useState(null);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("__cart"))
      ? JSON.parse(localStorage.getItem("__cart")).length
      : 0
  );
  const [update, setUpdate] = useState(false);
  const [pengikut, setPengikut] = React.useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchDataReseller = async () => {
      const result = await getProductData(match.params.slug);
      const resultSupplier = await getSupplier(result.data.supplier.slug);
      const id = await product_recomendation();
      const response = await getProductData(`?filter[category.id]=${id}`);
      await setSliderProduct(response.data);
      setData(result.data);
      setDataSupplier(resultSupplier.data);
      setPengikut(resultSupplier.data.stats.followers_count);
      setLoading(false);
    };

    const fetchDataCustomer = async () => {
      const result = await getProductData(match.params.slug);
      const resultSupplier = await getSupplier(result.data.supplier.slug);
      const resultStorefront = await getStorefront(match.params.reseller);
      const response = await getStorefrontProducts(match.params.reseller);
      setSliderProduct(response.data);
      setData(result.data);
      setDataSupplier(resultSupplier.data);
      setsupplierStoreFront(resultStorefront.data)
      setLoading(false);

      // add phone supplier storefron to sessionstorage
      sessionStorage.setItem('phone_supplier_storefront', JSON.stringify(resultStorefront.data.owner.phone))
    };

    if (isStoreFront) {
      fetchDataCustomer();
    } else {
      fetchDataReseller();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    isStoreFront &&
      localStorage.getItem("__cart") &&
      setCart(JSON.parse(localStorage.getItem("__cart")).length);
  }, [update]);

  const handleUpgradePremium = async () => {
    if (!isLoggedIn()) return (window.location = "/login");
    const id = JSON.parse(localStorage.getItem("USER_DATA")).id;
    const response = await upgradeAccount(id);
    if (response.token) {
      setOpenUpgradePremium(true);
      setTokenUpgradePremium(response.token);
    }
  };

  console.log(supplierStoreFront)

  return (
    <Fragment>
      {
        !isStoreFront && 
        <PopupDeeplink url={window.location.pathname} />
      }
      <NavbarProductMobile isStoreFront={isStoreFront} />
      {loading && !Boolean(dataSupplier) ? (
        <Loading />
      ) : (
        <Fragment>

          <Slider
            infinite={true}
            adaptiveHeight={true}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
          >
            {data.skus.map((res, index) => (
              <div key={index}>
                <img
                  src={res.image_url}
                  alt=""
                  style={{
                    width: '100%',
                    height: 360,
                    objectFit: "cover",
                    border: "0.1px solid white",
                  }}
                />
              </div>
            ))}
          </Slider>

          <Container maxWidth="lg">
            <Typography className={classes.titleProduct}>
              {data.name}
            </Typography>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              {!isStoreFront && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <Typography className={classes.tagProduct}>
                      Harga Reseller
                    </Typography>
                    <Typography className={classes.yellowColour}>
                      Rp.{" "}
                      {currentVariant === ""
                        ? numberSeparator(Number(data.skus[0].price.reseller))
                        : numberSeparator(
                          Number(currentVariant.price_reseller)
                        )}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={6}>
                <Typography className={classes.tagProduct}>
                  Harga {!isStoreFront && "Konsumen"}
                </Typography>
                <Typography className={classes.blackColour}>
                  Rp.{" "}
                  {isStoreFront ?
                    numberSeparator(Number(data.consumer_price_idr))
                  :
                    (currentVariant === ""
                    ? numberSeparator(Number(data.skus[0].price.consumer))
                    : numberSeparator(Number(currentVariant.price_consumer)))
                }
                </Typography>
              </Grid>
            </div>
            <Grid container style={{marginTop: 10, marginBottom: 10}}>
              {!isPremiumMember && !isStoreFront && (
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <Button
                    variant="contained"
                    className={classes.buttonResellerPremium}
                    fullWidth
                    startIcon={
                      <PremiumIcon style={{ height: 20, fill: "#ffffff" }} />
                    }
                    onClick={handleUpgradePremium}
                  >
                    <span style={{ fontSize: 12 }}>
                      Super Seller, harga lebih murah
                    </span>
                    <span className={classes.superSellerPrice}>
                      Rp. {numberSeparator(data.skus[0].price.reseller_premium)}
                    </span>
                  </Button>
                  <UpgradePremiumDialog
                    open={openUpgradePremium}
                    onClose={() => setOpenUpgradePremium(false)}
                    token={tokenUpgradePremium}
                  />
                </Grid>
              )}
            </Grid>
            {
              !isStoreFront &&
              <Grid container style={{marginTop: 5, marginBottom: 5}}>
              Komisi Anda 
                <div style={{ marginLeft: 5}}>
                  {data.commission.type === 'PERCENT' ? 
                    '(' + data.commission.value + '%) Rp. ' +
                    numberSeparator(Number(data.skus[0].price.consumer * data.commission.value / 100))
                    : 'Rp. ' + numberSeparator(Number(data.commission.value))
                }
              </div>
            </Grid>
            }
          </Container>

          <Container maxWidth="lg" style={{ marginTop: 5, marginBottom: 5 }}>
            <Grid container style={{ justifyContent: 'space-between' }} onClick={() => setOpenCekOngkir(true)}>
              <Grid item xs={10}>
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  Cek Kurir & Ongkos Kirim
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </Container>
          <Drawer
            anchor="right"
            open={openCekOngkir}
            onClose={() => setOpenCekOngkir(false)}
          >
            <CekOngkir
              dataSupplier={dataSupplier}
              supplier={data.supplier}
              onClose={() => setOpenCekOngkir(false)}
            />
          </Drawer>

          <Container maxWidth="lg" style={{ marginTop: 5, marginBottom: 5 }}>
            <Grid container onClick={() => setOpenVariant(true)}>
              <Grid item xs={11}>
                <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
                  Pilih Varian
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowForwardIcon />
              </Grid>
            </Grid>
          </Container>
          <Drawer
            anchor="bottom"
            open={openVariant}
            onClose={() => setOpenVariant(false)}
          >
            <Variant
              data={data}
              update={update}
              setUpdate={setUpdate}
              dataSupplier={dataSupplier}
              role="reseller"
              isStoreFront={isStoreFront}
              onClose={() => setOpenVariant(false)}
            />
          </Drawer>

          <Container>
            <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
              Informasi Barang
            </Typography>
            <table style={{width: 350}}>
              <tr>
                <td>Stok</td>
                <td>{currentVariant === ""
                  ? data.skus[0].stock
                  : currentVariant.stock}</td>
              </tr>
              <tr>
                <td>Material</td>
                <td>{data.skus[0].properties.material}</td>
              </tr>
              <tr>
                <td>Berat</td>
                <td>{data.skus[0].weight_gram} Gram</td>
              </tr>
              <tr>
                <td>Dikirim dari</td>
                  <td>{data.supplier.location.village.name}</td>
              </tr>
              <tr>
                <td>Terjual</td>
                <td>{data.total_sold}</td>
              </tr>
            </table>
              <hr />
          </Container>

          <Container maxWidth="lg">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                Deskripsi Produk
              </Typography>
              <CopyToClipboard
                text={newLineRevert(data.description).replace(/<[^>]*>?/gm, "")}
                onCopy={() => swal("Deskripsi disalin.")}
              >
                <Typography style={{ fontWeight: "bold" }} color="primary">
                  Salin Deskripsi
                </Typography>
              </CopyToClipboard>
            </div>

            <div
              style={{
                maxHeight: isReadMore ? "100%" : 220,
                overflow: "hidden",
              }}
            >
              <Typography>
                {data.description.split('\n').map((text) => (<p>{text}</p>))}
              </Typography>
            </div>
            <div
                style={{ cursor: "pointer", textAlign: "center", backgroundColor: '#eaeaea', color: '#5b5b5b', borderRadius: 5, padding: 8, marginTop: 5, marginBottom:10 }}
              onClick={() => setIsReadMore(!isReadMore)}
            >
              {isReadMore ? "Lihat Lebih Sedikit" : "Lihat Selegkapnya"}
            </div>
          </Container>

          <Container maxWidth="lg">
            <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center', marginTop: 5, marginBottom: 5}}>
              <Grid item xs={2}>
                {
                  isStoreFront ?
                  <div
                    style={{
                      background: `url(${supplierStoreFront?.avatar_url})`,
                    }}
                    className={classes.storeImage}
                  ></div>
                  :
                  <div
                    style={{
                      background: `url(${dataSupplier.avatar_url})`,
                    }}
                    className={classes.storeImage}
                  ></div>
                }
              </Grid>
              <div style={{marginLeft: 30}}>
                {
                  isStoreFront ?
                  <div style={{ fontSize: 14, fontWeight: "bold" }}>{supplierStoreFront?.name}</div>
                  :
                  <div style={{ fontSize: 14, fontWeight: "bold" }}>{dataSupplier.name}</div>
                }
                <div>
                  {
                    data.supplier.is_offline === false ?
                      <div style={{color: 'green'}}>Toko Buka</div>
                    :
                      <div style={{ color: 'red' }}>Toko Tutup</div>
                  }
                </div>
              </div>
            </div>
              <div 
                style={{ 
                  cursor: "pointer", 
                  textAlign: "center", 
                  backgroundColor: '#eaeaea', 
                  color: '#5b5b5b', 
                  borderRadius: 5, 
                  padding: 8, 
                  marginTop: 5, 
                  marginBottom: 10 }}
                onClick={() =>
                    history.push(
                      isStoreFront
                        ? `/store/${supplierStoreFront.slug}`
                        : `/profile/${dataSupplier.slug}`
                    )}
                  >
                    Kunjungi Toko
                  </div>
          </Container>

          <Container maxWidth="lg">
            <Typography style={{ marginBottom: 10, fontWeight: "bold" }}>
              Barang Terkait
            </Typography>
          </Container>
          <div style={{ marginBottom: 120 }}>
            <Carousel
              variant={'mobile'}
              data={sliderProduct}
            />
          </div>

          <BottomNavbar
            dataSupplier={dataSupplier}
            setOpenVariant={setOpenVariant}
            isStoreFront={isStoreFront}
            data={data}
          />

          {
            !isStoreFront &&
            <br/>
          }
        </Fragment>
      )}
    </Fragment>
  );
});

export default withRouter(Mobile);

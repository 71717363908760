const style = (theme) => ({
  card: {
    backgroundColor: "#f5f5f5",
    padding: "15px 10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  photoProfile: {
    height: 75,
    width: 75,
    borderRadius: "50%",
  },
  formControl: {
    marginLeft: 16,
    width: 200,
  },
  title: {
    fontWeight: 600,
    overflow: "hidden",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    paddingTop: 10,
    height: 55,
  },
  priceDiscount: {
    fontSize: "0.9rem",
    color: "#555555",
    paddingTop: 10,
    display: "flex",
    alignItems: "center",
  },
  pagination: {
    padding: 16,
    display: "flex",
    justifyContent: "center",
  },
});

export default style;

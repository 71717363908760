import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../../../components/navbar";
import { Tabs, Tab, Typography, Card, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import logoIdejualan from "../../../assets/images/logo.svg";
import { getCategories } from "../../../services/axios";
import Loading from "../../../components/loading";
import { withRouter } from "react-router-dom";

const Mobile = withStyles(styles)((props) => {
  const { classes, history } = props;
  const [value, setValue] = useState(0);
  const [listCategory, setListCategory] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await getCategories();
      setListCategory(response.data);
    };
    getData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        className={classes.contentWrapper}
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Grid style={{ padding: 10 }} container spacing={1}>
            {children}
          </Grid>
        )}
      </div>
    );
  }

  const handleImgError = (event) => {
    return (event.target.src = logoIdejualan);
  };

  return (
    <Fragment>
      {!listCategory ? (
        <Loading />
      ) : (
        <Fragment>
          <Navbar variant="primary" />
          <div className={classes.root}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              {listCategory.map((res, index) => (
                <Tab
                  wrapped={true}
                  label={
                    <div style={{ marginTop: 10 }}>
                      <img
                        src={res.icon_url}
                        onError={handleImgError}
                        height={50}
                        width={50}
                        alt={res.name}
                      />
                      <Typography>{res.name}</Typography>
                    </div>
                  }
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            {listCategory.map((res, index) => (
              <TabPanel value={value} index={index}>
                {res.children.map((res) => (
                  <Grid item xs={4}>
                    <Card
                      className={classes.card}
                      onClick={() =>
                        history.push(
                          `/category/${res.name.replace(" ", "-")}-cat.${
                            res.id
                          }`
                        )
                      }
                    >
                      <img
                        src={
                          res.icon_url !== null ? res.icon_url : logoIdejualan
                        }
                        onError={handleImgError}
                        height={res.icon_url !== null ? 50 : 50}
                        width={50}
                        alt={res.name}
                      />
                      <Typography variant="body2">{res.name}</Typography>
                    </Card>
                  </Grid>
                ))}
              </TabPanel>
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
});

export default withRouter(Mobile);

const AsCustomer = false;

export const stylesDesktop = (theme) => ({
  titleProduct: {
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: "1.2rem",
  },
  tagProduct: {
    color: "#555555",
    opacity: 0.65,
    paddingBottom: 10,
    fontSize: 14,
  },
  blackColour: {
    color: "#555555",
    fontWeight: 600,
  },
  yellowColour: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  redColour: {
    color: "#ff6564",
    fontWeight: 600,
  },
  variant: {
    fontWeight: 600,
    color: "#555555",
    marginBottom: 10,
  },
  slider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 290,
  },
  notSlider: {
    display: "flex",
    alignItems: "center",
  },
  variantFilled: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main} !important`,
    background: "rgba(253, 205, 15, 0.15)",
    boxSizing: "border-box",
    borderRadius: 15,
    cursor: "pointer",
    padding: "2px 16px",
    display: "flex",
    alignItems: "center",
  },
  variantProductText1: {
    margin: 0,
    opacity: 0.65,
    padding: "8px 0",
    fontSize: 11,
  },
  variantProduct1: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #555555",
    boxSizing: "border-box",
    borderRadius: 15,
    padding: "2px 16px",
    cursor: "pointer",
  },
  variantNotAvailable: {
    backgroundColor: "#aba9a9",
    border: "1px solid #aba9a9",
    color: "#555555",
    boxSizing: "border-box",
    borderRadius: 15,
    padding: "2px 16px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonMin: {
    background: "#29C0C7",
    marginRight: 10,
    borderRadius: 4,
    color: "#ffffff",
    fontSize: 50,
    lineHeight: 0,
    cursor: "pointer",
    outline: "none",
    border: "none",
    transition: "0.5s",
    "&:hover": {
      background: "#555555",
      transition: "0.5s",
      opacity: 0.35,
    },
  },
  buttonPlus: {
    background: "#29C0C7",
    marginLeft: "10px !important",
    borderRadius: 4,
    border: "none",
    color: "#ffffff",
    fontSize: 50,
    lineHeight: 0,
    cursor: "pointer",
    outline: "none",
    transition: "0.5s",
    "&:hover": {
      background: "#555555",
      transition: "0.5s",
      opacity: 0.35,
    },
  },
  quantityItem: {
    border: "none",
    cursor: "default",
    borderBottom: "1px solid #adabab",
    width: 60,
    height: "100%",
    fontSize: 20,
    lineHeight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    textAlign: "center",
    fontWeight: 800,
  },
  labelPrice: {
    color: "#555555",
    opacity: 0.65,
  },
  totalPrice: {
    color: "#555555",
    fontSize: 18,
    fontWeight: 600,
  },
  buttonSecondary: {
    padding: "5px 20px",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "0.79rem",
    height: 44,
    textTransform: "none",
  },
  buttonPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "8px 20px",
    background: theme.palette.primary.main,
    marginLeft: 20,
    fontSize: "0.79rem",
    height: 44,
    textTransform: "none",
  },
  buttonPrimary2: {
    padding: "8px 20px",
    marginLeft: 20,
    fontSize: "0.79rem",
    height: 44,
    textTransform: "none",
    color: "#a7a7a7",
  },
  verticalLine: {
    width: 1.2,
    minWidth: 1.2,
    height: "100%",
    background: "#929292",
    margin: "0px 0px 0px 24px",
  },
  storeDetail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRight: AsCustomer && "1px solid #555555",
  },
  storeDetail2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 30px",
  },
  storeImage: {
    height: 100,
    width: 100,
    backgroundPosition: "center !important",
    backgroundSize: "cover !important",
    borderRadius: "50%",
  },
  storeCity: {
    color: "#555555",
    opacity: 0.65,
    fontSize: "0.8rem",
    paddingBottom: 10,
  },
  followVendor: {
    height: 40,
    width: 140,
    background: "rgba(253, 205, 15, 0.15)",
    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
    borderRadius: 4,
    color: theme.palette.primary.main,
    cursor: "pointer",
    outline: "none",
    textTransform: "capitalize",
  },
  visitProfile: {
    height: 40,
    width: 140,
    border: `1px solid ${AsCustomer ? theme.palette.primary.main : "#555555"}`,
    boxSizing: "border-box",
    borderRadius: 4,
    color: AsCustomer ? theme.palette.primary.main : "#a7a7a7",
    background: AsCustomer ? "rgba(253, 205, 15, 0.15)" : "#ffffff",
    marginLeft: 10,
    cursor: "pointer",
    outline: "none",
    textTransform: "capitalize",
  },
  disabledButton: {
    height: 40,
    width: 140,
    textTransform: "capitalize",
    color: "#a7a7a7",
  },
  loadingDiv: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  priceDiscount: {
    fontSize: "0.9rem",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
  },
  pricePersen: {
    color: "#000000",
    fontWeight: "bold",
    background: theme.palette.primary.main,
    borderRadius: 4,
    padding: "3px 4px",
    marginLeft: 5,
  },
  resultPrice: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    paddingTop: 5,
  },
  customerCart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    height: 60,
    width: 60,
    bottom: 0,
    right: 0,
    marginBottom: 30,
    marginRight: 30,
    cursor: "pointer",
  },
  superSellerPrice: {
    fontWeight: 'bold',
    border: '1px solid #ffffff',
    borderRadius: 5,
    marginLeft: 5,
    padding: '0 5px'
  },
  buttonResellerPremium: {
    backgroundColor: "#29C0C7",
    color: "#ffffff",
    padding: 10,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: "#1b8388"
    }
  },
  withoutDisc: {
    color: "#afafaf",
    fontSize: '0.8rem',
    textDecoration: 'line-through',
  },
  disc: {
    color: "#ff0000",
  },
});

import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Mobile from "./mobile";
import Desktop from "./desktop";
import { useHistory } from "react-router-dom";

const ConfirmPage = () => {
  const history = useHistory()

  React.useEffect(() => {
    if (window.location.href.includes('campaign')) {
      const dataCampaign = JSON.parse(localStorage.getItem('data-campaign'))
      history.push(`/payment-campaign?campaign_slug=${dataCampaign.campaign_slug}`)
    }
  }, [])
  

  const isMobile = useMediaQuery("(max-width:500px)");
  return <React.Fragment>{isMobile ? <Mobile /> : <Desktop />}</React.Fragment>;
};

export default ConfirmPage;

import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import { stylesDesktop } from "./style";
import {
  Breadcrumbs,
  Container,
  Typography,
  Link,
  Grid,
  Divider,
  Badge,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import Carousel from "../../../components/carousel";
import "../style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { numberSeparator } from "../../../utils/numberSeparator";
import {
  getProductData,
  getSupplier,
  getStorefront,
  addToStorefront,
  deleteProductStorefront,
  getStorefrontProducts,
  upgradeAccount,
  isLoggedIn,
} from "../../../services/axios";
import Whatsapp from "../../../assets/icons/dashicons_whatsapp.svg";
import CekOngkir from "./components/CekOngkir";
import swal from "@sweetalert/with-react";
import AddToCart from "../../../components/AddToCart";
import formatCash from "../../../utils/formatCash";
import product_recomendation from "../../../utils/productsRecomendation";
import ShareSection from "./components/ShareSection";
import StoreDetail from "./components/StoreDetail";
import ProductDescription from "./components/ProductDescription";
import { withRouter } from "react-router-dom";
import Loading from "../../../components/loading";
import { Helmet } from "react-helmet";
import { add } from "cart-localstorage";
import { ReactComponent as PremiumIcon } from "../../../assets/icons/icon_premium.svg";
import UpgradePremiumDialog from "../../../components/upgrade-premium-dialog";
import CustomPriceReseller from "../../../components/CustomPriceReseller";

const Desktop = withStyles(stylesDesktop)((props) => {
  const { classes, match, history } = props;
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentVariant, setCurrentVariant] = useState(null);
  const [currentProperties, setCurrentProperties] = useState(null);
  const [dataSupplier, setDataSupplier] = useState(null);
  const [isAddedToStore, setIsAddedToStore] = useState(null);
  const [item, setItem] = useState({
    qty: 0,
    price: 0,
  });
  const [sliderProduct, setSliderProduct] = useState(null);
  const [openAddToCart, setOpenAddToCart] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState({
    code: 0,
    properties: { color: "", size: "" },
    price: { consumer: "", reseller: "" },
  });
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("__cart"))
      ? JSON.parse(localStorage.getItem("__cart")).length
      : 0
  );
  const [update, setUpdate] = useState(false);
  const isPremiumMember =
    JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;
  const [openUpgradePremium, setOpenUpgradePremium] = useState(false);
  const [tokenUpgradePremium, setTokenUpgradePremium] = useState("");
  const [showMoreSkus, setShowMoreSkus] = useState(false);
  const userData = JSON.parse(localStorage.getItem('USER_DATA'))
  const [isOpenCustomPriceReseller, setisOpenCustomPriceReseller] = useState(false)
  const [supplierStoreFront, setsupplierStoreFront] = useState()

  useEffect(() => {
    setCurrentProperties(null);
    setItem({
      qty: 0,
      price: 0,
    });
    setLoading(true);
    const fetchDataReseller = async () => {
      const result = await getProductData(match.params.slug);
      const resultSupplier = await getSupplier(result.data.supplier.slug);
      const id = await product_recomendation();
      const response = await getProductData(`?filter[category.id]=${id}`);
      setSliderProduct(response.data);
      setData(result.data);
      setDataSupplier(resultSupplier.data);
      setCurrentImage(result.data.image_url);
      setIsAddedToStore(result.data.is_added);
      setLoading(false);

      // default
      setSelectedSkus(result.data.skus[0]);
      setCurrentImage(result.data.skus[0].image_url);
      setCurrentProperties(result.data.skus[0].properties);
      setItem({
        qty: 1,
        price: item.price + result.data.skus[0].price.reseller,
      });
    };

    const fetchDataCustomer = async () => {
      const result = await getProductData(match.params.slug);
      const resultSupplier = await getSupplier(result.data.supplier.slug);
      const resultStorefront = await getStorefront(match.params.reseller);
      const response = await getStorefrontProducts(match.params.reseller);
      setSliderProduct(response.data);
      setData(result.data);
      setDataSupplier(resultSupplier.data);
      setsupplierStoreFront(resultStorefront.data)
      setLoading(false);

      // default
      setSelectedSkus(result.data.skus[0]);
      setCurrentProperties(result.data.skus[0].properties);
      setCurrentImage(result.data.skus[0].image_url);
      setItem({
        qty: 1,
        price: item.price + result.data.skus[0].price.consumer,
      });

      // add phone supplier storefron to sessionstorage
      sessionStorage.setItem('phone_supplier_storefront', JSON.stringify(resultStorefront.data.owner.phone))

      setLoading(false);
    };
    if (isStoreFront) {
      fetchDataCustomer();
    } else {
      fetchDataReseller();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    isStoreFront &&
      localStorage.getItem("__cart") &&
      setCart(JSON.parse(localStorage.getItem("__cart")).length);
  }, [update]);

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          height: 72,
        }}
      >
        <NavigateNextIcon className="slideNext" />
      </div>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          height: 72,
        }}
      >
        <NavigateBeforeIcon className="slidePrev" />
      </div>
    );
  }

  const handleClick = (operator) => {
    if (selectedSkus.price.reseller === "") {
      swal("Pilih varian terlebih dahulu");
    } else {
      const price = isStoreFront ? Number(selectedSkus.price.consumer) : Number(selectedSkus.price.reseller);
      if (operator === "+") {
        setItem({
          qty: parseInt(item.qty) + 1,
          price: item.price + price,
        });
      } else {
        item.qty !== 0 &&
          setItem({
            qty: parseInt(item.qty) - 1,
            price: item.price - price,
          });
      }
    }
  };

  const handleSetVariant = (data) => {
    setCurrentImage(data.image_url);
    setCurrentProperties(data.properties);
    setCurrentVariant({
      price_consumer: data.price.consumer,
      price_reseller: data.price.reseller,
      commission: data.price.commission,
      stock: data.stock,
      weight_gram: data.weight_gram,
    });
  };

  const customeSlider = React.createRef();

  const next = () => {
    customeSlider.current.slickNext();
  };
  const previous = () => {
    customeSlider.current.slickPrev();
  };

  const handleAddToStore = () => {
    if (!isLoggedIn()) return (window.location = "/login");
    setisOpenCustomPriceReseller(true)
  };

  const handleDeleteProductStorefront = () => {
    const storefront = JSON.parse(localStorage.getItem("USER_DATA")).storefront
      .slug;
    deleteProductStorefront(storefront, data.slug);
    setIsAddedToStore(false);
    swal("Success", "Berhasil dihapus dari Toko !", "success");
  };

  const handleAddToCart = () => {
    if (!isLoggedIn()) return (window.location = "/login");
    if (!currentProperties) {
     return swal("Oops!", "Pilih varian barang", "info");
    }
    if (
      (selectedSkus.properties.size === currentProperties.size) !==
      (selectedSkus.properties.color === currentProperties.color)
    ) {
      swal("Pilih varian yang tersedia");
    } else if (item.qty === 0) {
      swal("Masukan Jumlah barang");
    } else {
      setOpenAddToCart(true);
    }
  };

  const handleTambahKeranjangCustomer = () => {
    if (
      selectedSkus.properties.color === "" ||
      selectedSkus.properties.size === ""
    ) {
      swal("Oops!", "Pilih varian barang", "info");
    } else if (item.qty === 0) {
      swal("Oops!", "Masukan jumlah barang", "info");
    } else {
      add(
        {
          id: `${data.slug}-${selectedSkus.properties.color}-${selectedSkus.properties.size}`,
          img: selectedSkus.image_url,
          name: data.name,
          slug: data.slug,
          varian: selectedSkus.properties.color,
          size: selectedSkus.properties.size,
          weight_gram: selectedSkus.weight_gram,
          price: selectedSkus.price.consumer,
          supplier: dataSupplier.name,
          data_supplier: dataSupplier,
          selected_courier: dataSupplier.supported_couriers[0],
          properties: currentProperties,
          selectedSkus: selectedSkus,
          full_data_product: data,
        },
        item.qty
      );
      // sessionStorage.setItem("phone_reseller", dataSupplier.owner.phone);
      swal("Success", "Berhasil dimasukan keranjang!", "success").then(() => {
        setUpdate(!update);
      });
    }
  };

  const handleUpgradePremium = async () => {
    if (!isLoggedIn()) return (window.location = "/login");
    const id = JSON.parse(localStorage.getItem("USER_DATA"))?.id;
    const response = await upgradeAccount(id);
    if (response.token) {
      setOpenUpgradePremium(true);
      setTokenUpgradePremium(response.token);
    }
  };

  const handleSkus = (data) => {
    return data.map((res, index) => (
      <Grid item key={index}>
        <div
          className={
            selectedSkus.code === res.code
              ? classes.variantFilled
              : classes.variantProduct1
          }
          onClick={() => {
            setSelectedSkus(res);
            setCurrentImage(res.image_url);
            setCurrentProperties(res.properties);
            setItem({
              qty: 1,
              price: isStoreFront ? res.price.consumer : res.price.reseller,
            });
          }}
        >
          <Typography
            style={{ margin: 0 }}
            className={classes.variantProductText1}
          >
            {res.properties.color + ` ( ${res.properties.size} ) `}
          </Typography>
        </div>
      </Grid>
    ));
  }

  const renderSkus = () => {
    if (data.skus) {
      const lessSkus = handleSkus(data.skus.slice(0, 5));
      const moreSkus = handleSkus(data.skus.slice(5));

      return (
        <>
          {lessSkus}
          {showMoreSkus && moreSkus}
          <Grid item>
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={() => setShowMoreSkus(!showMoreSkus)}
            >
              {showMoreSkus ? "Lebih sedikit" : "Lebih banyak"}
            </Button>
          </Grid>
        </>
      );
    } else {
      return null;
    }
  }

  // console.log(dataSupplier)

  return (
    <Fragment>
      <Helmet>
        <title>{data.name ? data.name + " | " : ""} Idejualan</title>
      </Helmet>
      <Navbar variant={isStoreFront ? "default" : "primary"} />
      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <Container
            maxWidth="md"
            style={{ paddingTop: 40, paddingBottom: 40 }}
          >
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{ fontSize: 12 }}
            >
              <Link color="inherit" href="/">
                Home
              </Link>
              <Link color="inherit">{data.category.name}</Link>
              <Typography color="textPrimary" style={{ fontSize: 16 }}>
                {data.name.length > 69
                  ? data.name.slice(0, 69) + "..."
                  : data.name}
              </Typography>
            </Breadcrumbs>

            {isStoreFront && (
              <div
                className={classes.customerCart}
                  onClick={() => history.push("/cart-customer")}
              >
                <Badge badgeContent={cart} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </div>
            )}

            <Grid container style={{ marginTop: 40, marginBottom: 10 }}>
              <Grid item xs={4}>
                <div>
                  <div>
                    <img
                      src={currentImage}
                      alt=""
                      style={{
                        width: 300,
                        height: 300,
                        objectFit: "cover",
                        borderRadius: 8,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div
                    className={
                      data.skus.length > 4 ? classes.slider : classes.notSlider
                    }
                  >
                    {data.skus.length > 4 ? (
                      <Fragment>
                        <PrevArrow onClick={() => previous()} />
                        <Slider
                          infinite="false"
                          adaptiveHeight="true"
                          slidesToShow={
                            data.skus.length <= 4 ? data.skus.length : 4
                          }
                          slidesToScroll={1}
                          arrows={false}
                          ref={customeSlider}
                        >
                          {data.skus.map((res, index) => (
                            <div style={{ width: 70 }} key={index}>
                              <img
                                src={res.image_url}
                                alt=""
                                style={{
                                  borderRadius: 6,
                                  cursor: "pointer",
                                  border: "2.5px solid white",
                                }}
                                onClick={() => setCurrentImage(res.image_url)}
                              />
                            </div>
                          ))}
                        </Slider>

                        <NextArrow onClick={() => next()} />
                      </Fragment>
                    ) : (
                      <Fragment>
                        {data.skus.map((res, index) => (
                          <div style={{ width: 75, height: 75 }} key={index}>
                            <img
                              src={res.image_url}
                              alt=""
                              style={{
                                height: "100%",
                                width: "100%",
                                borderRadius: 6,
                                cursor: "pointer",
                                border: "2.5px solid white",
                              }}
                              onClick={() => handleSetVariant(res)}
                            />
                          </div>
                        ))}
                      </Fragment>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xs={8} style={{ paddingLeft: 30 }}>
                <Typography className={classes.titleProduct}>
                  {data.name}
                </Typography>

                <Divider />
                <Grid container spacing={2} style={{ margin: "2px 0" }}>
                  {isStoreFront ? (
                    <Fragment>
                      <Grid item>
                        <Typography className={classes.tagProduct}>
                          Harga
                        </Typography>
                        <Typography className={classes.blackColour}>
                          Rp{" "}
                          {
                            numberSeparator(Number(selectedSkus.price.consumer))
                          }
                        </Typography>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item xs={3}>
                        <Typography className={classes.tagProduct}>
                          Harga Konsumen
                        </Typography>
                        <Typography className={classes.blackColour}>
                          Rp.{" "}
                          {currentVariant === null
                            ? numberSeparator(
                                Number(data.skus[0].price.consumer)
                              )
                            : numberSeparator(
                                Number(currentVariant.price_consumer)
                              )}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.tagProduct}>
                          Harga Reseller
                        </Typography>

                        {/* discount price */}
                        <Typography className={classes.withoutDisc}>
                          <b>
                              {
                                data.skus[0].price.reseller_without_disc !== undefined
                                ? "Rp.  " +  numberSeparator(data.skus[0].price.reseller_without_disc)
                                : ""
                              }
                          </b>
                        </Typography>
                        {/* end of discount price */}

                        <Typography color="primary" className={data.skus[0].price.reseller_without_disc !== undefined ? classes.disc : classes.yellowColour}>
                          Rp.{" "}
                          {currentVariant === null
                            ? numberSeparator(
                                Number(data.skus[0].price.reseller)
                              )
                            : numberSeparator(
                                Number(currentVariant.price_reseller)
                              )}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.tagProduct}>
                          Anda Dapat Komisi
                        </Typography>
                        <Typography className={classes.redColour}>
                          Rp.{" "}
                          {currentVariant === null
                            ? numberSeparator(
                                Number(data.skus[0].price.commission)
                              )
                            : numberSeparator(
                                Number(currentVariant.price_consumer) -
                                  Number(currentVariant.price_reseller)
                              )}
                        </Typography>
                      </Grid>
                    </Fragment>
                  )}
                  <Grid item xs={3}>
                    <Fragment>
                      <Typography className={classes.tagProduct}>
                        Stok Tersedia
                      </Typography>
                      <Typography className={classes.blackColour}>
                        {currentVariant === null
                          ? data.skus[0].stock
                          : currentVariant.stock}
                      </Typography>
                    </Fragment>
                  </Grid>

                  {!isPremiumMember && !isStoreFront && (
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <Button
                        variant="contained"
                        className={classes.buttonResellerPremium}
                        fullWidth
                        startIcon={
                          <PremiumIcon
                            style={{ height: 20, fill: "#ffffff" }}
                          />
                        }
                        onClick={handleUpgradePremium}
                      >
                        <span style={{ fontSize: 12 }}>
                          Super Seller, harga lebih murah
                        </span>
                        <span className={classes}>
                          Rp. {numberSeparator(data.skus[0].price.reseller)}
                        </span>
                      </Button>
                      <UpgradePremiumDialog
                        open={openUpgradePremium}
                        onClose={() => setOpenUpgradePremium(false)}
                        token={tokenUpgradePremium}
                      />
                    </Grid>
                  )}
                </Grid>

                <Divider />
                <CekOngkir
                  dataSupplier={dataSupplier}
                  supplier={data.supplier}
                  match={match}
                />
                <Divider />

                <div style={{ margin: "10px 0" }}>
                  <Typography className={classes.variant}>
                    Varian Barang
                  </Typography>
                  <Grid container spacing={1} style={{ marginTop: 5 }} alignItems="center">
                    {renderSkus()}
                  </Grid>
                </div>
              </Grid>
            </Grid>

            {isStoreFront && (
              <Fragment>
                <Divider />
                <ShareSection />
              </Fragment>
            )}

            <Divider />

            {/* Add to Store */}
            <Grid container>
              <Grid
                item
                xs={4}
                style={{
                  margin: "25px 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ButtonGroup style={{ height: 44 }}>
                  <button
                    className={classes.buttonMin}
                    onClick={() => handleClick("-")}
                  >
                    -
                  </button>
                  <div>
                    <input
                      type="number"
                      className={classes.quantityItem}
                      onChange={(e) =>
                        setItem({
                          qty: e.target.value,
                          price:
                            e.target.value *
                            (currentVariant === ""
                              ? Number(data.skus[0].price.reseller)
                              : Number(currentVariant.price_reseller)),
                        })
                      }
                      value={item.qty}
                    />
                  </div>
                  <button
                    className={classes.buttonPlus}
                    onClick={() => handleClick("+")}
                  >
                    +
                  </button>
                </ButtonGroup>

                <div style={{ marginLeft: 20 }}>
                  <Typography className={classes.labelPrice}>Total</Typography>
                  <Typography className={classes.totalPrice}>
                    Rp {numberSeparator(item.price)}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {isStoreFront ? (
                  <Fragment>
                    {/* <Button
                      variant="outlined"
                      className={classes.buttonSecondary}
                      style={{ marginLeft: 20 }}
                      onClick={() =>
                        window.open(
                          `https://wa.me/${dataSupplier.owner.phone}?text=Hai,%20ini%20idejualan`,
                          "_blank"
                        )
                      }
                    >
                      Chat Via WA&nbsp;
                      <img src={Whatsapp} alt="" />
                    </Button> */}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonPrimary}
                      onClick={handleTambahKeranjangCustomer}
                    >
                      Tambahkan ke Keranjang
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Button
                      variant="outlined"
                      className={classes.buttonSecondary}
                      onClick={handleAddToCart}
                    >
                      Tambahkan ke Keranjang
                    </Button>
                    <Button
                      variant="outlined"
                      className={classes.buttonSecondary}
                      style={{ marginLeft: 20 }}
                      onClick={() =>
                        window.open(
                          `http://api.idejualan.com/whatsapp?text=Hai,%20ini%20idejualan`,
                          "_blank"
                        )
                      }
                    >
                      Chat Via WA&nbsp;
                      <img src={Whatsapp} alt="" />
                    </Button>
                    {isAddedToStore === true && isAddedToStore !== null ? (
                      <Button
                        variant="contained"
                        className={classes.buttonPrimary2}
                        onClick={handleDeleteProductStorefront}
                      >
                        Hapus dari Toko
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonPrimary}
                          onClick={handleAddToStore}
                        >
                          Tambah Ke Toko
                        </Button>
                      </>
                    )}
                    {
                      isLoggedIn() &&
                      <CustomPriceReseller 
                        open={isOpenCustomPriceReseller} 
                        setIsOpen={setisOpenCustomPriceReseller} 
                        product={data.slug} 
                        storefrontName={userData?.storefront?.slug}
                        isPremiumMember={isPremiumMember}
                        setIsAddedToStore={setIsAddedToStore}
                      />
                    }
                  </Fragment>
                )}
              </Grid>
            </Grid>

            <Divider />
            <StoreDetail dataSupplier={isStoreFront ? supplierStoreFront : dataSupplier} />
            <Divider />
            <ProductDescription data={data} />
            <Divider />

            {sliderProduct && sliderProduct.length !== 0 && (
              <div style={{ margin: "20px 0" }}>
                <Typography style={{ paddingBottom: 20, fontWeight: "bold" }}>
                  {isStoreFront
                    ? "Produk lain dari toko ini"
                    : "Rekomendasi untuk kamu"}
                </Typography>
                <Carousel
                  variant={isStoreFront && "customer"}
                  data={sliderProduct}
                />
              </div>
            )}
          </Container>
        </Fragment>
      )}

      <AddToCart
        handleClose={() => setOpenAddToCart(false)}
        open={openAddToCart}
        data={data}
        colorVariant={selectedSkus.properties.color}
        sizeVariant={selectedSkus.properties.size}
        currentImage={currentImage}
        currentVariant={currentVariant}
        dataSupplier={dataSupplier}
        item={item}
        currentProperties={currentProperties}
        selectedSkus={selectedSkus}
      />
      <Footer />
    </Fragment>
  );
});

export default withRouter(Desktop);

import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Drawer,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import {
  getAllCity,
  getSubdistrict,
  checkShippingCost,
  getSupplierSupportedCourier,
} from "../../../../services/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { numberSeparator } from "../../../../utils/numberSeparator";

import Loading from "../../../../components/loading";
import Navbar from "../../../../components/navbar";
import DividerMobile from "../../../../components/divider-mobile";

const useStyles = makeStyles((theme) => ({
  divCity: {
    display: "flex",
    cursor: "pointer",
  },
  city: {
    fontWeight: "bold",
  },
  regulerCourier: {
    fontWeight: "bold",
  },
  dividerCourier: {
    margin: "10px 0",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  gratisOngkir: {
    color: theme.palette.secondary.main,
  },
  minimalBuy: {
    fontWeight: "bold",
  },
  desc: {
    width: "85%",
  },
  fullWidth: {
    width: window.screen.width,
  },
  closeIcon: {
    height: 40,
    width: "100%",
  },
  toCenter: {
    display: "flex",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 20,
  },
  cityItem: {
    padding: "10px 0",
  },
  forwardIcon: {
    height: "100%",
    width: "100%",
  },
  wrapper: {
    width: window.screen.width,
  },
}));

const CekOngkir = (props) => {
  const classes = useStyles();
  const AsCustomer = false;
  const {dataSupplier, supplier} = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentCity, setCurrentCity] = useState(null);
  const [currentCourier, setCurrentCourier] = useState(
    AsCustomer ? supplier.supported_couriers[0] : dataSupplier.supported_couriers[0]
  );
  const [listSubdistrict, setListSubdistrict] = useState([]);
  const [isCity, setIsCity] = useState(true);
  const [currentSubdistrict, setCurrentSubdistrict] = useState(null);
  const [listOngkir, setListOngkir] = useState(null);
  const [search, setSearch] = useState("");
  const [listCity, setListCity] = useState(null);
  const [searchedCity, setSearchedCity] = useState(null);
  const [searchedSubdistrict, setSearchedSubdistrict] = useState(null);
  const [loading, setLoading] = useState(true);
  const [supportedCourier, setSupportedCourier] = useState(null);
  const [listPrice, setListPrice] = useState([]);
  const [minOngkir, setMinOngkir] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const getCity = await getAllCity("");
      const getCurrentCity = await getAllCity("?filter[name]=jakarta pusat");
      const getSubdistricts = await getSubdistrict(152);
      const getListCourier = await getSupplierSupportedCourier(
        AsCustomer ? supplier.slug : dataSupplier.slug_supplier
      );
      setListCity(getCity.data);
      setSupportedCourier(getListCourier.data);
      setSearchedCity(getCity.data);
      setCurrentCity(getCurrentCity.data[0]);
      setCurrentSubdistrict(getSubdistricts.data[0])
      setLoading(false);
    };
    fetch();
  }, []);

  const handleChangeCity = async (response) => {
    setSearch("")
    const getSubdistricts = await getSubdistrict(response.id);
    setCurrentCity(response);
    setListSubdistrict(getSubdistricts.data)
    setSearchedSubdistrict(getSubdistricts.data)
  };

  const handleChangeCourier = (event) => {
    setCurrentCourier(event.target.value);
  };

  const handleCheckShippingCost = async () => {
    setListOngkir(null);
    const data = {
      origin_subdistrict: 2096,
      origin_city: 152,
      destination_subdistrict: currentSubdistrict.id,
      destination_city: currentCity.id,
      weight_gram: 1000,
      courier: currentCourier.code,
    };
    const response = await checkShippingCost(data);
    setListOngkir(response);
  };

  useEffect(() => {
    if (currentSubdistrict !== null) handleCheckShippingCost();
  }, [currentCity, currentSubdistrict, currentCourier]);

  useEffect(() => {
    const getListHarga = async () => {
      let list_harga = [];
      const list_kurir = AsCustomer
        ? supportedCourier
        : dataSupplier.supported_couriers;
      list_kurir.forEach(async (res) => {
        let data = {
          origin_subdistrict: 2096,
          origin_city: 152,
          destination_subdistrict: currentSubdistrict.id,
          destination_city: currentCity.id,
          weight_gram: 1000,
          courier: res.code,
        };
        const response = await checkShippingCost(data);
        response[0].costs.map((res) => {
          res.cost.map((res) => {
            list_harga = [...list_harga, res.value];
          });
        });
        setListPrice(list_harga);
      });
    };
    currentSubdistrict !== null && getListHarga();
  }, [currentCity, currentSubdistrict]);

  useEffect(() => {
    listPrice.length !== 0 && setMinOngkir(Math.min.apply(Math, listPrice));
  }, [listPrice]);

  const handleSearch = (keyword) => {
    setSearch(keyword);
  };

  useEffect(() => {
    const filteredLocation = () => {
      const titleCase = (str) => {
        var splitStr = str.toLowerCase().split(" ");
        for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
      };

      const regex = new RegExp(titleCase(search));
      const filteredCity = listCity.filter(({ name }) => name.match(regex));
      const filteredSubdistrict = listSubdistrict.filter(({ name }) => name.match(regex));
      if(isCity){
        if (search === null) {
          setSearchedCity(filteredCity);
        } else if (search.length === 0) {
          setSearchedCity(listCity);
        } else {
          setSearchedCity(filteredCity);
        }
      }else {
        if (search === null) {
          setSearchedSubdistrict(filteredSubdistrict);
        } else if (search.length === 0) {
          setSearchedSubdistrict(listSubdistrict);
        } else {
          setSearchedSubdistrict(filteredSubdistrict);
        }
      }
      
    };
    if(isCity){
      if (listCity) filteredLocation();
    }else {
      if (listSubdistrict) filteredLocation();
    }
  }, [search]);

  return (
    <React.Fragment>
      <Navbar text="Cek Kurir dan Ongkos Kirim" onClick={props.onClose} />
      {loading ? (
        <div className={classes.wrapper}>
          <Loading />
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Container maxWidth="lg">
            <Typography variant="subtitle2">Dikirim ke</Typography>
            <div
              className={classes.divCity}
              onClick={() => setOpenDrawer(true)}
            >
              <Typography className={classes.city}>
                {currentCity.name}, {currentSubdistrict.name}
              </Typography>
              <ExpandMoreIcon />
            </div>
          </Container>

          <DividerMobile />

          <Container maxWidth="lg">
            <div>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%", marginBottom: 10 }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Pilih Kurir
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currentCourier}
                  style={{ width: "100%", display: "flex" }}
                  label="Pilih Kurir"
                  onChange={handleChangeCourier}
                >
                  {supportedCourier && (AsCustomer
                    ? supportedCourier
                    : dataSupplier.supported_couriers
                  ).map((res, index) => {
                    return (
                      <MenuItem value={res} key={index}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography style={{ width: "80%" }}>
                            {res.name}
                          </Typography>
                          <img src={res.icon_url} style={{maxWidth: 50}} alt="" />
                        </div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {!listOngkir ? (
                    <div className={classes.loading}>
                      <CircularProgress />
                    </div>
                  ) : (
                    listOngkir[0].costs.map((res, index) => {
                      return (
                        <Fragment key={index}>
                          <Grid item xs={12}>
                            <div className={classes.courier}>
                              <Typography>
                                {res.description} ({res.service})
                              </Typography>
                              <Typography>Rp {numberSeparator(res.cost[0].value)}</Typography>
                            </div>
                            <div>
                              <Typography variant="caption">
                                Lama Pengiriman{" "}
                                {res.cost[0].etd.length === 0
                                  ? '"tidak diketahui"'
                                  : res.cost[0].etd +
                                    (currentCourier.code === "pos"
                                      ? ""
                                      : " Hari")}
                              </Typography>
                            </div>
                          </Grid>
                        </Fragment>
                      );
                    })
                  )}
                </Grid>
              </Grid>
            </div>
          </Container>

          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <div className={classes.fullWidth}>
              <Navbar
                text="Cek Kurir dan Ongkos Kirim"
                onClick={() => setOpenDrawer(false)}
              />
              <Container maxWidth="lg">
                <Grid container>
                  <Grid item xs={10}>
                    <TextField
                      label={isCity ? "Cari Kabupaten/Kota" : "Cari Kecamatan"}
                      fullWidth={true}
                      value={search}
                      type="text"
                      variant="outlined"
                      onChange={(event) => handleSearch(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.toCenter}>
                    <CancelRoundedIcon className={classes.closeIcon} />
                  </Grid>
                </Grid>
                {isCity ? (
                  <Fragment>
                    {searchedCity &&
                      searchedCity.map((response) => {
                        return (
                          <MenuItem
                            value={response}
                            key={response.id}
                            onClick={() => {
                              handleChangeCity(response)
                              setIsCity(false);
                            }}
                          >
                            {`${
                              response.type === "Kabupaten"
                                ? response.type.slice(0, 3) + "."
                                : response.type
                            } ${response.name}`}
                          </MenuItem>
                        );
                      })}
                  </Fragment>
                ) : (
                  <Fragment>
                    {searchedSubdistrict &&
                      searchedSubdistrict.map((response) => {
                        return (
                          <MenuItem
                            value={response}
                            key={response.id}
                            onClick={() => {
                              setCurrentSubdistrict(response);
                              setSearch("")
                              setIsCity(true);
                              setOpenDrawer(false);
                            }}
                          >
                            {response.name}
                          </MenuItem>
                        );
                      })}
                  </Fragment>
                )}
              </Container>
            </div>
          </Drawer>
        </div>
      )}
    </React.Fragment>
  );
};

export default CekOngkir;

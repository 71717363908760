import axios from "axios";
import swal from "@sweetalert/with-react";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let getToken = localStorage.getItem("TOKEN");

  config.headers = Object.assign(
    {
      Authorization: getToken,
    },
    config.headers
  );
  return config;
});

axiosInstance.interceptors.request.use((config) => {
  let getToken = localStorage.getItem("TOKEN");

  config.headers = Object.assign(
    {
      Authorization: "Bearer " + getToken,
    },
    config.headers
  );
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === "phone_verification_required"
    ) {
      swal({
        title:
          "No Handphone ada belum ter-verifikasi, silahkan verifikasi terlebih dahulu",
        icon: "info",
      }).then(() => {
        window.location.replace("/verif-phone");
      });
    } else if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      swal({ title: "Sesi berakhir silahkan login ulang", icon: "info" }).then(
        () => {
          localStorage.clear();
          window.location.reload();
        }
      );
    } else if (
      error.response.status === 401 &&
      window.location.pathname === "/login"
    ) {
      swal({ title: error.response.data.error, icon: "info" }).then(() =>
        window.location.reload()
      );
    } else if (error.response.status === 500) {
      swal("Oops!", "Ups, data tidak ditemukan", "warning").then(() =>
        window.location.reload()
      );
    } else {
      if (window.location.pathname === "/login") {
        swal("Oops!", "Email dan Password tidak cocok", "warning").then(() =>
          window.location.reload()
        );
      } else if (
        window.location.pathname === "/signup" &&
        error.response.status === 422
      ) {
        swal(
          "Oops!",
          "Ups, terjadi kesalahan " + error.response.data.errors.email,
          "warning"
        ).then(() => window.location.reload());
      } else {
        swal(
          "Oops",
          "Ups, terjadi kesalahan " + error.response.status,
          "warning"
        ).then(() => window.location.reload());
      }
    }
    // if (window.confirm('Terjadi kesalahan, silahkan muat ulang')) {
    //   window.location.reload();
    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

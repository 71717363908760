import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import {
  Grid,
  Typography,
  Container,
  Divider,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Button,
  InputBase,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import storeIcon from "../../../assets/icons/shop.svg";
import EditIcon from "@material-ui/icons/Edit";
import { numberSeparator } from "../../../utils/numberSeparator";
import {
  checkShippingCostV2,
  getCustomers,
  checkout,
} from "../../../services/axios";
import { getPayments } from "../../../services/order.service";
import Loading from "../../../components/loading";
import OpsiPengiriman from "./components/OpsiPengiriman";
import ChangeAddress from "./components/ChangeAddress";
import ReactMidtrans from "../../../config/midtrans";
import ApplyVoucher from "./components/ApplyVoucher";
import swal from "sweetalert";
import Toggle from 'react-toggle'
import "react-toggle/style.css" 

const Desktop = withStyles(styles)((props) => {
  const { classes } = props;
  const history = useHistory();
  const data = JSON.parse(localStorage.getItem("SELECTED_CART"));
  const reseller_id = JSON.parse(localStorage.getItem("USER_DATA")).id;
  const [dataCheckout, setDataCheckout] = useState(null);
  const [openOpsiPengiriman, setOpenOpsiPengiriman] = useState({
    status: false,
    data: null,
    customer: null,
  });
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [openChangeAddress, setOpenChangeAddress] = useState(false);
  const [listCustomers, setListCustomers] = useState(null);
  const [update, setUpdate] = useState(false);
  const [midtrans, setMidtrans] = useState({
    clientKey: "SB-Mid-client-xYSFi8v3uCnhGICn",
    token: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("midtrans");
  const isPremiumMember = JSON.parse(localStorage.getItem("USER_DATA"))?.activated || false;
  const [isIncludeCod, setisIncludeCod] = useState(false)
  const [isOpenDialogCOD, setisOpenDialogCOD] = useState(false)
  const [isButtonDisabled, setisButtonDisabled] = useState(true)
  const [isSetujuCod, setisSetujuCod] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [isNotOrdinarySeller, setisNotOrdinarySeller] = useState()

  // check is not ordinary seller
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("USER_DATA"))) {
      if (JSON.parse(localStorage.getItem("USER_DATA")).level.name === 'Seller') {
        setisNotOrdinarySeller(false)
      }else{
        setisNotOrdinarySeller(true)
      }
    }
  }, [])
  

  useEffect(() => {
    setTimeout(() => {
      if (isOpenDialogCOD === true) {
        setisButtonDisabled(false)
      }
    }, 5000);
  }, [isOpenDialogCOD])
  

  const handleCheckShippingCost = async (
    supplierId,
    destinationPostalCode,
    destinationSubDistrictName,
    couriers,
    weight
  ) => {
    const formData = new FormData();
    formData.append("supplier_id", supplierId);
    formData.append("destination_postal_code", destinationPostalCode);
    formData.append(
      "destination_sub_district_name",
      destinationSubDistrictName
    );
    formData.append("shipment_type", "DROP");
    formData.append("package_type_id", "1");
    formData.append("weight", Math.ceil(weight / 1000));
    formData.append("is_cod", "0");
    for (let i = 0; i < couriers.length; i++) {
      formData.append("couriers[]", couriers[i]);
    }
    const response = await checkShippingCostV2(formData);
    return response;
  };

  const filteredFullCustomer = (data) => {
    // console.log(data);
    let returnData = [];
    let returnDataTrue = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.customer.id)] !== res.customer.id) {
        returnData = [...returnData, res.customer.id];
        returnDataTrue = [...returnDataTrue, res.customer];
      }
    });
    return returnDataTrue;
  };

  const filteredFullSupplier = (data) => {
    let returnData = [];
    let returnDataTrue = [];
    data.map((res) => {
      if (returnData[returnData.indexOf(res.supplier)] !== res.supplier) {
        returnData = [...returnData, res.supplier];
        returnDataTrue = [...returnDataTrue, res.data_supplier];
      }
    });
    return returnDataTrue;
  };

  const filteredCourierCode = (data) => {
    let returnData = [];
    data.forEach((res) => {
      if (returnData[returnData.indexOf(res.code)] !== res.code) {
        returnData = [...returnData, res.code];
      }
    });
    return returnData;
  };

  const getTotalWeight = (data) => {
    let returnData = 0;
    data.forEach((res) => {
      returnData = returnData + res.weight_gram;
    });
    return returnData;
  };

  const getOngkir = async (supplier, customer) => {
    const response = await handleCheckShippingCost(
      supplier.id,
      customer.village?.kodepos || customer.postal_code,
      customer.village?.name,
      filteredCourierCode(supplier.supported_couriers),
      getTotalWeight(
        data
          .filter((value) => value.customer.name === customer.name)
          .filter((item) => item.supplier === supplier.name)
      )
    );
    return response;
  };

  const getDataCheckout = async () => {
    let dataResult = [];
    const listCustomer = filteredFullCustomer(data);
    // console.log(listCustomer);
    for (let ic = 0; ic < listCustomer.length; ic++) {
      let listSupplier = filteredFullSupplier(
        data.filter((value) => value.customer.name === listCustomer[ic].name)
      );
      for (let is = 0; is < listSupplier.length; is++) {
        const resListOngkir = await getOngkir(
          listSupplier[is],
          listCustomer[ic]
        );

        let selectedOngkir = null;

        for (const key in resListOngkir) {
          if (Object.hasOwnProperty.call(resListOngkir, key)) {
            if (resListOngkir[key] && resListOngkir[key].length > 0) {
              selectedOngkir = resListOngkir[key][0];
              break;
            }
          }
        }
        dataResult = [
          ...dataResult,
          {
            id: ic,
            customer: listCustomer[ic],
            data_supplier: [
              {
                id: is,
                supplier: listSupplier[is],
                list_ongkir: resListOngkir,
                selected_ongkir: selectedOngkir,
                catatan: "",
                products: data
                  .filter(
                    (value) => value.customer.name === listCustomer[ic].name
                  )
                  .filter((item) => item.supplier === listSupplier[is].name),
              },
            ],
            data_voucher: null,
          },
        ];
      }
    }
    setDataCheckout(dataResult);
    setCurrentCustomer(dataResult[0].customer);
  };

  useEffect(() => {
    const fetch = async () => {
      const customers = await getCustomers(reseller_id);
      setListCustomers(customers.data);
    };
    fetch();
  }, []);

  useEffect(() => {
    getDataCheckout();
  }, [update]);

  const getNameCustomer = (data) => {
    let resultData = [];
    data.forEach((res) => {
      if (res.name !== undefined) {
        resultData = [...resultData, res.name];
      } else {
        resultData = [...resultData, "undefined"];
      }
    });
    return resultData;
  };

  const getAddressCustomer = (data) => {
    let resultData = [];
    data.forEach((res) => {
      if (res.name !== undefined) {
        resultData = [...resultData, res.address];
      }
    });
    return resultData;
  };

  const handleChangeShippment = (costs, supplier, customer) => {
    setOpenOpsiPengiriman({ ...openOpsiPengiriman, status: false });
    setDataCheckout([
      ...dataCheckout.filter((value) => value.customer.name !== customer.name),
      {
        ...dataCheckout.filter(
          (value) => value.customer.name === customer.name
        )[0],
        data_supplier: [
          ...dataCheckout
            .filter((value) => value.customer.name === customer.name)[0]
            .data_supplier.filter(
              (value) => value.supplier.name !== supplier.name
            ),
          {
            ...dataCheckout
              .filter((value) => value.customer.name === customer.name)[0]
              .data_supplier.filter(
                (value) => value.supplier.name === supplier.name
              )[0],
            selected_ongkir: {
              ...costs,
            },
          },
        ],
      },
    ]);
  };

  const handleCatatan = (value, supplier, customer) => {
    setDataCheckout([
      ...dataCheckout.filter((value) => value.customer.name !== customer.name),
      {
        ...dataCheckout.filter(
          (value) => value.customer.name === customer.name
        )[0],
        data_supplier: [
          ...dataCheckout
            .filter((value) => value.customer.name === customer.name)[0]
            .data_supplier.filter(
              (value) => value.supplier.name !== supplier.name
            ),
          {
            ...dataCheckout
              .filter((value) => value.customer.name === customer.name)[0]
              .data_supplier.filter(
                (value) => value.supplier.name === supplier.name
              )[0],
            catatan: value,
          },
        ],
      },
    ]);
  };

  // non cod price
  const filteredPrice = (data) => {
    let returnData = [];
    data.map((res) => {
      returnData = [...returnData, res.price * res.quantity];
    });
    return returnData;
  };

  const getTotalPrice = (res) => {
    let returnData = [];
    res.data_supplier.forEach(
      (res) => (returnData = [...returnData, filteredPrice(res.products)])
    );

    if (returnData.length === 1) {
      if (returnData[0].length === 1) {
        return returnData
      }else{
        let finalData = 0
        for (let i = 0; i < returnData[0].length; i++) {
          finalData =  finalData + returnData[0][i]
        }
        return finalData
      }
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };

  const getAllTotalPrice = () => {
    let returnData = [];

    dataCheckout
    .filter((data) => data.data_supplier[0].is_cod !== true)
    .forEach(
      (res) =>
        (returnData = [
          ...returnData,
          Number(getTotalPrice(res)),
          Number(getTotalOngkir(res)),
          -Number(res.data_voucher ? res.data_voucher.amount : 0),
        ])
    );

    if (returnData.length === 1) {
      return returnData[0];
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };
  // end non cod price

  // cod price
  const filteredCodPrice = (data) => {
    let returnData = [];
    data.map((res) => {
      returnData = [...returnData, res.price_cod ? res.price_cod * res.quantity : 0];
    });
    return returnData;
  };

  const getTotalCodPrice = (res) => {
    let returnData = [];
    res.data_supplier.forEach(
      (res) => (returnData = [...returnData, filteredCodPrice(res.products)])
    );

    if (returnData.length === 1) {
      if (returnData[0].length === 1) {
        return returnData
      }else{
        let finalData = 0
        for (let i = 0; i < returnData[0].length; i++) {
          finalData =  finalData + returnData[0][i]
        }
        return finalData
      }
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };

  const getAllTotalCodPrice = () => {
    let returnData = [];

    dataCheckout
    .filter((data) => data.data_supplier[0].is_cod === true)
    .forEach(
      (res) =>
        (returnData = [
          ...returnData,
          Number(getTotalCodPrice(res)),
          Number(getTotalOngkir(res)),
          Number(getPenangananCod(res)),
          -Number(res.data_voucher ? res.data_voucher.amount : 0),
        ])
    );

    if (returnData.length === 1) {
      return returnData[0];
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };

  const getPenangananCod = (res) => {
    return (Math.ceil((Number(getTotalCodPrice(res)) + Number(getTotalOngkir(res))) * res.data_supplier[0].selected_ongkir.fee_cod))
  }

  const getTotalKomisiCod = (res) => {
    let priceCodData = [];
    let priceSkuData = [];
    let quantityProduct = []
    let finalData = 0
    const  filteredCodPriceWithouthQuantity = (data) => {
      let myData = [];
      data.map((res) => {
        myData = [...myData, res.price_cod ? res.price_cod : 0];
      });
      return myData;
    }

    const filteredSkuPriceWithouthQuantity = (data) => {
      let myData = [];
      data.map((res) => {
        myData = [...myData, res.price];
      });
      return myData;
    }

    const filteredQuantity = (data) => {
      let myData = [];
      data.map((res) => {
        myData = [...myData, res.quantity];
      });
      return myData;
    }
    
    res.data_supplier.forEach(
      (res) => (priceCodData = [...priceCodData, filteredCodPriceWithouthQuantity(res.products)])
    );

    res.data_supplier.forEach(
      (res) => (priceSkuData = [...priceSkuData, filteredSkuPriceWithouthQuantity(res.products)])
    );

    res.data_supplier.forEach(
      (res) => (quantityProduct = [...quantityProduct, filteredQuantity(res.products)])
    );

    if (priceCodData[0].length !== 1) {
      for (let i = 0; i < priceCodData.length; i++) {
        finalData = finalData + ((Number(priceCodData[0][i]) - Number(priceSkuData[0][i])) * Number(quantityProduct[0][i]))
      }
    }else{
      for (let i = 0; i < priceCodData.length; i++) {
        finalData = finalData + ((Number(priceCodData[i]) - Number(priceSkuData[i])) * Number(quantityProduct[i]))
      }
    }

    if (finalData < 0) {
      return 0
    }else{
      return finalData
    }
  }
  // end cod price

  const getTotalOngkir = (response) => {
    let returnData = [];
    response.data_supplier.forEach(
      (res) => (returnData = [...returnData, res?.selected_ongkir?.price])
    );

    if (returnData.length === 1) {
      return returnData;
    } else {
      return returnData.reduce((a, b) => a + b, 0);
    }
  };

  const BootstrapInput = withStyles((theme) => ({
    input: {
      padding: "10px 14px",
      border: "1px solid #ced4da",
      borderRadius: 4,
    },
  }))(InputBase);

  //  ~~~~~ payload non cod ~~~~~~~
  const getproductsOrder = (data) => {
    let resultData = [];
    data.forEach((res) => {
      resultData = [
        ...resultData,
        { sku_code: res.selectedSkus.code, qty: res.quantity },
      ];
    });
    return resultData;
  };

  const getOrders = (data, dataVoucher) => {
    let resultData = [];
    data.forEach((res) => {
      resultData = [
        ...resultData,
        {
          supplier_id: res.supplier.id,
          voucher_id: dataVoucher ? dataVoucher.id : '',
          courier: res.selected_ongkir?.courier_code,
          courier_type: res.selected_ongkir?.rate_code,
          courier_estimated: `${res.selected_ongkir?.min_duration}-${res.selected_ongkir?.max_duration}`,
          shipping_cost: res.selected_ongkir?.price,
          customer_note: res.catatan,
          products: getproductsOrder(res.products),
        },
      ];
    });
    return resultData;
  };

  const payloadOrder = () => {
    let resultData = [];
    dataCheckout
    .filter((data) => data.data_supplier[0].is_cod !== true)
    .forEach((res) => {
      if (
        getNameCustomer(listCustomers).indexOf(res.customer.name) === -1 ||
        getAddressCustomer(listCustomers).indexOf(res.customer.address) === -1
      ) {
        resultData = [
          ...resultData,
          {
            customer: {
              name: res.customer.name,
              address: res.customer.address,
              phone: res.customer.phone,
              postal_code: res.customer.postal_code,
              village_id: res.customer.village.id,
            },
            orders: getOrders(res.data_supplier, res.data_voucher),
          },
        ];
      } else {
        resultData = [
          ...resultData,
          {
            customer: {
              name: res.customer.name,
              address: res.customer.address,
              phone: res.customer.phone,
              postal_code: res.customer.postal_code,
              village_id: res.customer.village.id,
            },
            orders: getOrders(res.data_supplier, res.data_voucher),
          },
        ];
      }
    });
    return { data: resultData };
  };
  //  ~~~~~ end payload non cod ~~~~~~~

  // ~~~~~~ payload cod ~~~~~~~~~
  const getproductsCODOrder = (data) => {
    let resultData = [];
    data.forEach((res) => {
      resultData = [
        ...resultData,
        { sku_code: res.selectedSkus.code, qty: res.quantity, cod_price: res.price_cod },
      ];
    });
    return resultData;
  };

  const getCODOrders = (data, dataVoucher) => {
    let resultData = [];
    data.forEach((res) => {
      resultData = [
        ...resultData,
        {
          supplier_id: res.supplier.id,
          voucher_id: dataVoucher ? dataVoucher.id : '',
          courier: res.selected_ongkir?.courier_code,
          courier_type: res.selected_ongkir?.rate_code,
          courier_estimated: `${res.selected_ongkir?.min_duration}-${res.selected_ongkir?.max_duration}`,
          shipping_cost: res.selected_ongkir?.price,
          customer_note: res.catatan,
          cod: {
            min_duration: res.selected_ongkir?.min_duration,
            max_duration: res.selected_ongkir?.max_duration,
            fee_cod: res.selected_ongkir?.fee_cod,
            is_cod: true
        },
          products: getproductsCODOrder(res.products),
        },
      ];
    });
    return resultData;
  };

  const payloadCODOrder = () => {
    let resultData = [];
    dataCheckout
    .filter((data) => data.data_supplier[0].is_cod === true)
    .forEach((res) => {
      if (
        getNameCustomer(listCustomers).indexOf(res.customer.name) === -1 ||
        getAddressCustomer(listCustomers).indexOf(res.customer.address) === -1
      ) {
        resultData = [
          ...resultData,
          {
            customer: {
              name: res.customer.name,
              address: res.customer.address,
              phone: res.customer.phone,
              postal_code: res.customer.postal_code,
              village_id: res.customer.village.id,
            },
            orders: getCODOrders(res.data_supplier, res.data_voucher),
          },
        ];
      } else {
        resultData = [
          ...resultData,
          {
            customer: {
              name: res.customer.name,
              address: res.customer.address,
              phone: res.customer.phone,
              postal_code: res.customer.postal_code,
              village_id: res.customer.village.id,
            },
            orders: getCODOrders(res.data_supplier, res.data_voucher),
          },
        ];
      }
    });
    return { data: resultData };
  };

  // ~~~~~ end payload cod ~~~~~~

  const filteredCart = (data) => {
    let returnData = [];
    const selectedCart = JSON.parse(localStorage.getItem("SELECTED_CART"));
    
    data.map((res) => {
      if (
        selectedCart[selectedCart.findIndex((value) => value.id === res.id)] ===
        undefined
      ) {
        returnData = [...returnData, res];
      }
    });
    return returnData;
  };

  const getNewToken = async () => {
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    const payload = payloadOrder();

    // filter cart
    await localStorage.setItem("__cart", JSON.stringify(filteredCart(getCart)));

    const response = await checkout(payload);
    setMidtrans({ ...midtrans, token: response.data.token });
    initSnap(response.data.token)
  };

  const initSnap = (token) => {
    const { NODE_ENV: ENV } = process.env;

    // create element for script
    const snapScript = document.createElement("script");

    // checking environment mode
    snapScript.src =
        ENV === "production"
        ? 
            window.origin.includes('dev') ?
            "https://app.sandbox.midtrans.com/snap/snap.js"
            :
            "https://app.midtrans.com/snap/snap.js"
        : "https://app.sandbox.midtrans.com/snap/snap.js";

    snapScript.type = "text/javascript";
    snapScript.onload = () => {
    if ("snap" in window) {
        const { snap } = window;
        snap.pay(token)
    }
    };
    snapScript.dataset.clientKey = midtrans.clientKey;
    document.head.appendChild(snapScript);
  };

  const handlePayVendorBank = async () => {
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    const payload = payloadOrder();
    payload.pay_with_vendorbank = true;
    const response = await checkout(payload);
    // filter cart
    localStorage.setItem("__cart", JSON.stringify(filteredCart(getCart)));
    if (response) {
      history.push(`/checkout/vendor-bank/${response.data.payment.id}`);
    }
  };

  const checkIsIncludeCOD = async(allData) => {
    for (let i = 0; i < allData?.length; i++) {
      if (allData[i].data_supplier[0].is_cod === true) {
        setisIncludeCod(true)
        return
      }
    }

    setisIncludeCod(false)
    return
  }

  useEffect(() => {
    checkIsIncludeCOD(dataCheckout)
  }, [dataCheckout])

  const handleChooseVoucher = (value, supplier, customer) => {
    setDataCheckout([
      ...dataCheckout.filter((value) => value.customer.name !== customer.name),
      {
        ...dataCheckout.filter(
          (value) => value.customer.name === customer.name
        )[0],
        data_voucher: value,
      },
    ]);
  };

  const handleChangeCod = (value, indexDataCheckout, indexSupplier) => {
    let newData = JSON.parse(JSON.stringify(dataCheckout))
    newData[indexDataCheckout].data_supplier[indexSupplier].is_cod = value
    setDataCheckout(newData)
  }

  const handleFillPriceCod = (value, indexDataCheckout, indexSupplier, indexProduct) => {
    let newData = JSON.parse(JSON.stringify(dataCheckout))
    newData[indexDataCheckout].data_supplier[indexSupplier].products[indexProduct].price_cod = Number(value)
    setDataCheckout(newData)
  }

  const handleOpenDialogCod = () => {
    // check is price cod filled
    for (let i = 0; i < dataCheckout?.length; i++) {
      if (dataCheckout[i].data_supplier[0].is_cod === true) {
        // check product
        for (let a = 0; a < dataCheckout[i].data_supplier[0].products.length; a++) {
          if (dataCheckout[i].data_supplier[0].products[a].price_cod === undefined) {
            swal({
              text: "Maaf anda belum mengisi harga cod",
              icon: "error",
              buttons: false,
            });
            return
          }else if(dataCheckout[i].data_supplier[0].products[a].price_cod === ''){
            swal({
              text: "Maaf anda belum mengisi harga cod",
              icon: "error",
              buttons: false,
            });
            return
          }else if (dataCheckout[i].data_supplier[0].products[a].price > dataCheckout[i].data_supplier[0].products[a].price_cod) {
            swal({
              text: "Maaf harga cod harus lebih besar dari harga normal",
              icon: "error",
              buttons: false,
            });
            return
          }
        }
      }
    }

    setisOpenDialogCOD(true)
  }

  const handlePayCod = async() => {
    const getCart = JSON.parse(localStorage.getItem("__cart"));
    setisSetujuCod(false)

    // filter cart
    await localStorage.setItem("__cart", JSON.stringify(filteredCart(getCart)));

    if (getAllTotalPrice() === 0) {
      // pembayaran COD semua
      setisLoading(true)
      try {
        const payload = payloadCODOrder();
        const response = await checkout(payload);
        if (response.data.success === true) {
          swal({
            title: "COD Success",
            text: "Your order is success",
            icon: "success",
            button: false,
          });
          setisLoading(false)
          history.push(`/cod/${response.data.payment.id}`)
        }
      } catch (error) {
        swal({
          title: "COD Failed",
          text: "Your order is failed",
          icon: "error",
          button: false,
        });
        setisLoading(false)
      }
    }else{
      if (paymentMethod === 'midtrans') {
        // COD dan Midtrans
        setisLoading(true)
        try {
          const payload = payloadCODOrder();
          const response = await checkout(payload);
          if (response.data.success === true) {
            swal({
              title: "COD Success",
              text: "Your order is success",
              icon: "success",
              button: false,
            });
            setisLoading(false)

            // pembayaran midtrans
            const payloadMidtrans = payloadOrder();

            const responseMidtrans = await checkout(payloadMidtrans);
            setMidtrans({ ...midtrans, token: responseMidtrans.data.token });
            initSnap(responseMidtrans.data.token)
          }
        } catch (error) {
          swal({
            title: "COD Failed",
            text: "Your order is failed",
            icon: "error",
            button: false,
          });
          setisLoading(false)
        }
      }else{
        // COD dan Bank
        setisLoading(true)
        try {
          const payload = payloadCODOrder();
          const response = await checkout(payload);
          if (response.data.success === true) {
            swal({
              title: "COD Success",
              text: "Your order is success",
              icon: "success",
              button: false,
            });
            setisLoading(false)

            // pembayaran vendorbank
            const payloadBank = payloadOrder();
            payloadBank.pay_with_vendorbank = true;
            const responseBank = await checkout(payloadBank);
            if (responseBank) {
              history.push(`/checkout/vendor-bank/${responseBank.data.payment.id}`);
            }
          }
        } catch (error) {
          swal({
            title: "COD Failed",
            text: "Your order is failed",
            icon: "error",
            button: false,
          });
          setisLoading(false)
        }
      }
    }
  }

  console.log(dataCheckout)

  return (
    <Fragment>
      <Navbar variant="primary" />
      {dataCheckout === null ? (
        <Loading />
      ) : (
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              {dataCheckout
                .sort(function (a, b) {
                  return a.customer.name > b.customer.name
                    ? 1
                    : b.customer.name > a.customer.name
                    ? -1
                    : 0;
                })
                .map((checkout, indexDataCheckout) => {
                  return (
                    <Fragment key={indexDataCheckout}>
                      <Typography color="primary" style={{ paddingBottom: 10 }}>
                        <b>{`Atas Nama ${checkout.customer.name}`}</b>
                      </Typography>
                      {checkout.data_supplier.map((supplier, indexSupplier) => {
                        return (
                          <Fragment key={indexSupplier}>
                            <div className={classes.bg}>
                              <div className={classes.detailBelanja}>
                                <Typography>
                                  <b>Detail Belanja</b>
                                </Typography>
                              </div>
                              <Divider />
                              <div className={classes.content}>
                                <div className={classes.storeText}>
                                  <img src={storeIcon} alt="" />
                                  <Typography style={{ paddingLeft: 10 }}>
                                    <b>{supplier.supplier.name}</b>
                                  </Typography>
                                </div>
                                <Fragment>
                                  {supplier.products.map((res, indexProduct) => (
                                    <Fragment key={indexProduct}>
                                      <div
                                        style={{
                                          display: "flex",
                                          paddingTop: 20,
                                          marginBottom: 10
                                        }}
                                      >
                                        <img
                                          src={res.img}
                                          height={150}
                                          width={150}
                                          alt={res.name}
                                          className={classes.image}
                                        />
                                        <div
                                          style={{
                                            paddingLeft: 20,
                                            width: "100%",
                                          }}
                                        >
                                          <div style={{ height: "100%" }}>
                                            <div>
                                              <Typography>
                                                <b>{res.name}</b>
                                              </Typography>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  paddingTop: 10,
                                                }}
                                              >
                                                <div>
                                                  <Typography variant="caption">
                                                    <b>Variant : </b>
                                                  </Typography>
                                                  <Typography variant="caption">
                                                    {`${res.size}, ${res.varian}`}
                                                  </Typography>
                                                </div>
                                                <Typography
                                                  color="primary"
                                                  variant="caption"
                                                >
                                                  <b>
                                                    @Rp{" "}
                                                    {numberSeparator(res.price)}
                                                  </b>
                                                </Typography>
                                              </div>
                                            </div>

                                            <div style=
                                            {
                                              supplier.is_cod ?
                                              {display: 'flex', flexDirection: 'column'}
                                              :
                                              {display: 'flex', flexDirection: 'column', height: '100%'}
                                            }
                                          >
                                            {/* harga COD */}
                                            {
                                              supplier.is_cod &&
                                              <div>
                                                <br/>
                                                <div style={
                                                  res.price_cod > res.price ?
                                                  {marginBottom: 5, color: 'green'}
                                                  :
                                                  {marginBottom: 5, color: 'red'}
                                                }>Harga COD (Bayar Di Tempat)</div>
                                                <TextField
                                                  type={'number'}
                                                  label="Masukkan Harga COD disini"
                                                  helperText="* Harga satuan, tidak termasuk ongkir"
                                                  variant="outlined"
                                                  value={res.price_cod}
                                                  className={classes.textField}
                                                  onChange={(e) =>
                                                    handleFillPriceCod(
                                                      e.target.value,
                                                      indexDataCheckout,
                                                      indexSupplier,
                                                      indexProduct
                                                    )
                                                  }
                                                />
                                                <br/><br/>
                                              </div>
                                            }

                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "space-between",
                                                height: "50%",
                                              }}
                                            >
                                              <Typography>{`${numberSeparator(
                                                res.quantity
                                              )} buah Item`}</Typography>
                                              <Typography>
                                                <b>
                                                  Rp{" "}
                                                  {numberSeparator(
                                                    res.price * res.quantity
                                                  )}
                                                </b>
                                              </Typography>
                                            </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ))}
                                </Fragment>
                              </div>
                            </div>

                            {/* COD */}
                            {
                              isNotOrdinarySeller &&
                              <Fragment>
                                {
                                  supplier.products[0].data_supplier.is_cod &&
                                    <Grid container className={classes.codBg}>
                                      <Grid item xs={2}>
                                        <Typography>COD</Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Toggle
                                          defaultChecked={false}
                                          onChange={(e) =>
                                            handleChangeCod(
                                              e.target.checked,
                                              indexDataCheckout,
                                              indexSupplier
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                }
                              </Fragment>
                            }

                            {/* kurir */}
                            <Grid container className={classes.kurirBg}>
                              <Grid item xs={2}>
                                <Typography>Kurir</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography color="secondary">
                                  Opsi Pengiriman:
                                </Typography>
                                {supplier.selected_ongkir ? (
                                  <div>
                                    <Typography>
                                      <b>
                                        {supplier?.selected_ongkir?.rate_name} (
                                        {supplier?.selected_ongkir?.rate_code})
                                      </b>
                                    </Typography>

                                    <Typography>
                                      {supplier?.selected_ongkir?.logistic_name}
                                    </Typography>
                                    <Typography variant="caption">
                                      Perkiraan Pengiriman{" "}
                                      {supplier?.selected_ongkir?.min_duration}{" "}
                                      {"-"}
                                      {
                                        supplier?.selected_ongkir?.max_duration
                                      }{" "}
                                      hari
                                    </Typography>
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.flexAlignCenter}
                              >
                                <Typography
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setOpenOpsiPengiriman({
                                      status: true,
                                      customer: checkout.customer,
                                      data: supplier,
                                    })
                                  }
                                >
                                  {supplier?.selected_ongkir ? "UBAH" : "PILIH"}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                className={classes.flexAlignCenter}
                              >
                                <Typography>
                                  <b>
                                    Rp{" "}
                                    {numberSeparator(
                                      supplier?.selected_ongkir?.price || 0
                                    )}
                                  </b>
                                </Typography>
                              </Grid>
                            </Grid>

                            <OpsiPengiriman
                              handleClose={() =>
                                setOpenOpsiPengiriman({
                                  ...openOpsiPengiriman,
                                  status: false,
                                })
                              }
                              open={openOpsiPengiriman.status}
                              data={openOpsiPengiriman.data}
                              customer={openOpsiPengiriman.customer}
                              handleChangeShippment={handleChangeShippment}
                            />

                            <div className={classes.catatanBg}>
                              <Grid item xs={2}>
                                <Typography>Catatan</Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  className={classes.formControl}
                                  id="outlined-multiline-static"
                                  placeholder="(Optional) Tinggalkan pesan ke penjual"
                                  multiline
                                  rows={5}
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleCatatan(
                                      e.target.value,
                                      supplier.supplier,
                                      checkout.customer
                                    )
                                  }
                                />
                              </Grid>
                            </div>
                            <ApplyVoucher
                              slug={supplier.supplier.slug}
                              choosedVoucher={checkout.data_voucher}
                              handleChooseVoucher={(value) =>
                                handleChooseVoucher(
                                  value,
                                  supplier.supplier,
                                  checkout.customer
                                )
                              }
                            />
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.detailContainer}>
                <div>
                  {currentCustomer && (
                    <div className={classes.cardBackground}>
                      <Typography style={{ padding: 10 }}>
                        <b>Detail Pembeli</b>
                      </Typography>
                      <Divider />
                      <div style={{ padding: 10 }}>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <Select
                            value={currentCustomer.name}
                            input={<BootstrapInput />}
                          >
                            {dataCheckout.map((res) => (
                              <MenuItem
                                value={res.customer.name}
                                onClick={() => setCurrentCustomer(res.customer)}
                              >
                                {res.customer.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Typography
                          variant="caption"
                          style={{ color: "#555555", marginTop: 10 }}
                        >
                          {`${currentCustomer?.address} 
                   ${currentCustomer?.village?.name || ""}
                 ${currentCustomer?.village?.district?.name || ""} ${
                            currentCustomer?.village?.district?.city?.name || ""
                          } ${
                            currentCustomer?.village?.district?.city?.province
                              ?.name || ""
                          }
                 ${currentCustomer?.village?.kodepos || ""} Indonesia`}
                        </Typography>
                        <div
                          className={classes.ubahAlamat}
                          onClick={() => setOpenChangeAddress(true)}
                        >
                          <EditIcon style={{ height: 15, cursor: "pointer" }} />
                          <Typography variant="caption">
                            <b>Ubah Alamat</b>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={classes.bg}
                    style={{ marginTop: 20, padding: 10 }}
                  >
                    <div style={{ padding: "10px 0" }}>
                      {dataCheckout.map((res) => (
                        <Fragment>
                          <div className={classes.flexBetween}>
                            <Typography variant="caption" color="primary">
                              Penerima
                            </Typography>
                            <Typography variant="caption" color="primary">
                            {res.customer.name}
                            </Typography>
                          </div>
                          <div className={classes.flexBetween}>
                            <Typography variant="caption">
                              Subtotal
                            </Typography>
                            <Typography variant="caption">
                              Rp {numberSeparator(getTotalPrice(res))}
                            </Typography>
                          </div>
                          <div className={classes.flexBetween}>
                            <Typography variant="caption">Ongkir</Typography>
                            <Typography variant="caption">
                              Rp {numberSeparator(getTotalOngkir(res))}
                            </Typography>
                          </div>
                          {
                            res.data_supplier[0].is_cod === true &&
                            <>
                              <div className={classes.flexBetween}>
                                <Typography variant="caption">Total Biaya COD Per Penerima</Typography>
                                <Typography variant="caption">
                                  Rp {numberSeparator((Number(getTotalCodPrice(res)) + Number(getTotalOngkir(res))))}
                                </Typography>
                              </div>
                              <div className={classes.flexBetween}>
                                <Typography variant="caption">Biaya Penanganan COD</Typography>
                                <Typography variant="caption">
                                  Rp {numberSeparator(getPenangananCod(res))}
                                </Typography>
                              </div>
                              <div className={classes.flexBetween}>
                                <Typography variant="caption">Total Komisi</Typography>
                                <Typography variant="caption">
                                  Rp {numberSeparator(getTotalKomisiCod(res))}
                                </Typography>
                              </div>
                            </>
                          }
                          <div className={classes.flexBetween} style={{marginBottom: 5}}>
                            <Typography variant="caption">Voucher</Typography>
                            <Typography
                              variant="caption"
                              style={{ color: "green" }}
                            >
                              -Rp{" "}
                              {numberSeparator(
                                res.data_voucher ? res.data_voucher.amount : 0
                              )}
                            </Typography>
                          </div>
                        </Fragment>
                      ))}
                      {
                        isIncludeCod === true &&
                        <>
                          <Divider />
                          <div
                            className={classes.flexBetween}
                            style={{ padding: "15px 0" }}
                          >
                            <Typography>
                              <b>Total COD</b>
                            </Typography>
                            <Typography>
                              <b>Rp {numberSeparator(Number(getAllTotalCodPrice()))}</b>
                            </Typography>
                          </div>
                        </>
                      }

                    </div>
                    {
                      getAllTotalPrice() !== 0 &&
                      <>
                        <Divider />
                        <div
                          className={classes.flexBetween}
                          style={{ padding: "15px 0" }}
                        >
                          <Typography>
                            <b>{isIncludeCod ? 'Total Non COD' : 'Total Harga'}</b>
                          </Typography>
                          <Typography>
                            <b>Rp {numberSeparator(getAllTotalPrice())}</b>
                          </Typography>
                        </div>
                        <Box mb={2}>
                          <RadioGroup
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                          >
                            <FormControlLabel
                              value="midtrans"
                              control={<Radio />}
                              label={
                                <Typography variant="caption">MidTrans</Typography>
                              }
                            />
                            <FormControlLabel
                              value="vendorBank"
                              control={<Radio />}
                              label={
                                <Typography variant="caption">
                                  Transfer Bank (dicek otomatis)
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </Box>
                      </>
                    }

                    {/* Button Pembayaran */}
                    {
                      getAllTotalPrice() === 0 ?
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        onClick={handleOpenDialogCod}
                      >
                        Pesan COD
                      </Button>
                      :
                        paymentMethod === 'midtrans' ?
                          isIncludeCod === true ?
                          <>
                            <ReactMidtrans
                              clientKey={midtrans.clientKey}
                            >
                                <div></div>
                            </ReactMidtrans>
                            <Button variant="contained" onClick={handleOpenDialogCod} color="primary" fullWidth={true}>
                              Pembayaran Otomatis & COD
                            </Button>
                          </>
                          :
                          <>
                            <ReactMidtrans
                              clientKey={midtrans.clientKey}
                            >
                                <div></div>
                            </ReactMidtrans>
                            <Button variant="contained" onClick={getNewToken} color="primary" fullWidth={true}>
                              Pembayaran Otomatis
                            </Button>
                          </>
                        :
                        isIncludeCod === true ?
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          onClick={handleOpenDialogCod}
                        >
                          Pembayaran Bank & COD
                        </Button>
                        :
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                          onClick={handlePayVendorBank}
                        >
                          Pembayaran Bank
                        </Button>
                    }
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}

      {currentCustomer && (
        <ChangeAddress
          setUpdate={setUpdate}
          update={update}
          open={openChangeAddress}
          handleClose={() => setOpenChangeAddress(false)}
          selectedCustomer={currentCustomer}
        />
      )}

      {/* dialog cod */}
      <Dialog
        onClose={() => {setisOpenDialogCOD(false)}}
        aria-labelledby="customized-dialog-title"
        open={isOpenDialogCOD}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => {setisOpenDialogCOD(false)}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Kebijakan Bayar di Tempat</Typography>
            <CloseIcon
              style={{ height: 32, width: "auto", cursor: "pointer" }}
              onClick={() => {setisOpenDialogCOD(false)}}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <>
            <div>Dengan ini saya sebagai Reseller Idejualan menyetujui syarat dan ketentuan pembelian bayar di tempat (COD) sebagai berikut:</div>
            <br/>
            <div>
              <div>1. Biaya penanganan COD akan ditanggung oleh customer (Fee penanganan COD berbeda setiap ekspedisi).</div>
                <br/>
              <div>2. Jika terjadi kegagalan pengiriman atau paket ditolak oleh customer maka akan dikenakan biaya pengiriman pengambalian produk kepada supplier.</div>
                <br/>
              <div>3. Biaya pengembalian produk karena gagal COD akan ditanggung sepenuhnya oleh pihak reseller.</div>
                <br/>
              <div>4. Akun Reseller akan ditangguhkan jika reseller memiliki tagihan pada poin ke-3 karena gagal COD, dan akan diaktifkan kembali jika sudah melunasi tagihan tersebut.</div>
                <br/>
              <div>5. Produk yang akan diretur atau dikembalikan ke supplier harus sesuai dengan paket pertama kali diterima.</div>
                <br/>
              <div>6. Jika paket sudah dibuka dan tetap dikembalikan reseller harus bertanggung jawab jika produk hilang atau tidak sesuaidengan yang dikirimkan oleh supplier.</div>
                <br/>
            </div>
          </>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            disabled={isButtonDisabled}
            onClick={async() => {
              await setisOpenDialogCOD(false)
              setisSetujuCod(true)
            }}
          >
            Setuju
          </Button>
        </DialogContent>
      </Dialog>

      {/* dialog setuju cod */}
      <Dialog
        onClose={() => {setisSetujuCod(false)}}
        aria-labelledby="customized-dialog-title"
        open={isSetujuCod}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => {setisSetujuCod(false)}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Apakah Anda Yakin Memilih COD</Typography>
            <CloseIcon
              style={{ height: 32, width: "auto", cursor: "pointer" }}
              onClick={() => {setisSetujuCod(false)}}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Button
              variant="contained"
              color="secondary"
              style={{width: '40%'}}
              disabled={isButtonDisabled}
              onClick={() => {
                setisSetujuCod(false)
              }}
            >
              Tidak
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{width: '40%'}}
              disabled={isButtonDisabled}
              onClick={() => {
                handlePayCod()
              }}
            >
              Ya
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* loading */}
      <Dialog
        open={isLoading}
      >
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <Footer />
    </Fragment>
  );
});

export default Desktop;

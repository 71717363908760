import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Popover,
  FormControl,
  Select,
  Grid,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  getAllCity,
  getSubdistrict,
  checkShippingCost,
  getSupplierSupportedCourier,
} from "../../../../services/axios";

const useStyles = makeStyles((theme) => ({
  variant: {
    fontWeight: 600,
    color: "#555555",
    marginBottom: 10,
  },
  fromCity: {
    fontSize: 13,
    color: "#555555",
    opacity: 0.65,
  },
  toCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  from: {
    color: "#555555",
    opacity: 0.65,
    margin: 0,
    fontSize: 13,
  },
  bold: {
    fontWeight: 600,
    paddingLeft: 10,
    fontSize: 13,
  },
  containerPopover: {
    padding: 15,
    display: "flex",
  },
  subDistrict: {
    height: 56,
    display: "flex",
    alignItems: "center",
  },
  codePos: {
    height: 56,
    marginTop: 15,
    display: "flex",
    alignItems: "center",
  },
  containerFieldPopover: {
    paddingLeft: 10,
  },
  fieldSubdistrict: {
    display: "block",
    paddingTop: 15,
  },
  containerPopoverCourier: {
    width: 370,
    padding: 15,
  },
  regulerCourier: {
    fontWeight: "bold",
  },
  dividerCourier: {
    margin: "10px 0",
  },
  courier: {
    display: "flex",
    justifyContent: "space-between",
  },
  gratisOngkir: {
    color: theme.palette.secondary.main,
  },
  minimalBuy: {
    fontWeight: "bold",
  },
}));

const CekOngkir = (props) => {
  const classes = useStyles();
  const { dataSupplier, supplier, match } = props;
  const isStoreFront = match.path.indexOf("/store/:reseller") !== -1;

  const [listCity, setListCity] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [supportedCourier, setSupportedCourier] = useState(null);
  // const [currentCourier, setCurrentCourier] = useState(
  //   isStoreFront
  //     ? supplier.supported_couriers[0]
  //     : dataSupplier.supported_couriers[0]
  // );
  const [listSubdistrict, setListSubdistrict] = useState(null);
  const [currentSubdistrict, setCurrentSubdistrict] = useState(null);
  const [listOngkir, setListOngkir] = useState(null);
  const [minOngkir, setMinOngkir] = useState(0);
  const [listPrice, setListPrice] = useState([]);
  const [anchorElSubDistrict, setAnchorElSubDistrict] = useState(null);
  const [anchorElCourier, setAnchorElCourier] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const getCity = await getAllCity("");
      const getCurrentCity = await getAllCity(`?filter[name]=jakarta pusat`);
      const getSubdistricts = await getSubdistrict(152);
      const getListCourier = await getSupplierSupportedCourier(
        isStoreFront ? supplier.slug : dataSupplier.slug
      );
      setListCity(getCity.data);
      setSupportedCourier(getListCourier.data);
      setCurrentCity(getCurrentCity.data[0]);
      setListSubdistrict(getSubdistricts.data);
      setCurrentSubdistrict(getSubdistricts.data[0]);
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (currentCity !== null) {
        const getSubdistricts = await getSubdistrict(currentCity.id);
        setListSubdistrict(getSubdistricts.data);
      }
    };
    fetch();
  }, [currentCity]);

  const handleChangeCity = async (response) => {
    const getSubdistricts = await getSubdistrict(response.id);
    setCurrentCity(response);
    setCurrentSubdistrict(getSubdistricts.data[0]);
  };

  // const handleChangeCourier = (event) => {
  //   setCurrentCourier(event.target.value);
  // };

  const handleCheckShippingCost = async () => {
    const data = {
      origin_subdistrict: 2096,
      origin_city: 152,
      destination_subdistrict: currentSubdistrict.id,
      destination_city: currentCity.id,
      weight_gram: 1000,
      // courier: currentCourier.code,
    };
    const response = await checkShippingCost(data);
    setListOngkir(response);
  };

  // useEffect(() => {
  //   if (currentSubdistrict !== null) handleCheckShippingCost();
  // }, [currentCity, currentSubdistrict, currentCourier]);

  useEffect(() => {
    const getListHarga = async () => {
      let list_harga = [];
      const list_kurir = isStoreFront
        ? supportedCourier
        : dataSupplier.supported_couriers;
      // list_kurir.forEach(async (res) => {
      //   let data = {
      //     origin_subdistrict: 2096,
      //     origin_city: 152,
      //     destination_subdistrict: currentSubdistrict.id,
      //     destination_city: currentCity.id,
      //     weight_gram: 1000,
      //     courier: res.code,
      //   };
      //   const response = await checkShippingCost(data);
      //   response[0].costs.map((res) => {
      //     res.cost.map((res) => {
      //       list_harga = [...list_harga, res.value];
      //     });
      //   });
      //   setListPrice(list_harga);
      // });
    };
    currentSubdistrict !== null && getListHarga();
  }, [currentCity, currentSubdistrict]);

  useEffect(() => {
    listPrice.length !== 0 && setMinOngkir(Math.min.apply(Math, listPrice));
  }, [listPrice]);

  const handleClickPopoverOngkir = (event) => {
    setAnchorElSubDistrict(event.currentTarget);
  };
  const handleClickPopoverCourier = (event) => {
    setAnchorElCourier(event.currentTarget);
  };

  const openOngkirTo = Boolean(anchorElSubDistrict);
  const openCourier = Boolean(anchorElCourier);
  const id = openOngkirTo ? "simple-popover" : undefined;
  const idCourier = openCourier ? "simple-popover" : undefined;

  const handleCloseSubDistrict = () => {
    setAnchorElSubDistrict(null);
  };
  const handleCloseCourier = () => {
    setAnchorElCourier(null);
  };

  return (
    <Fragment>
      <div style={{ margin: "10px 0" }}>
        <Typography className={classes.variant}>Cek Ongkos Kirim</Typography>
        <Typography className={classes.fromCity}>
          Dari Kota{" "}
          {isStoreFront
            ? supplier.location.address
            : dataSupplier.location.address}
        </Typography>
        <div style={{ display: "flex" }}>
          <div className={classes.toCenter}>
            <p className={classes.from}>Ke&nbsp;</p>
            <div
              className={classes.toCenter}
              style={{ cursor: "pointer" }}
              onClick={handleClickPopoverOngkir}
            >
              <Typography className={classes.bold}>
                {currentCity !== null && currentCity.name}
              </Typography>
              <ExpandMoreIcon style={{ height: 20 }} />
            </div>
          </div>
          <Popover
            id={id}
            open={openOngkirTo}
            anchorEl={anchorElSubDistrict}
            onClose={handleCloseSubDistrict}
            anchorOrigin={{ vartical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <div className={classes.containerPopover}>
              <div>
                <Typography className={classes.subDistrict}>
                  Kota/Kab
                </Typography>
                <Typography className={classes.codePos}>Kecamatan</Typography>
              </div>
              <div className={classes.containerFieldPopover}>
                <FormControl variant="outlined" style={{ width: 200 }}>
                  <Select
                    id="demo-simple-select-outlined"
                    value={currentCity !== null && currentCity.name}
                  >
                    {listCity !== null &&
                      listCity.map((response) => {
                        return (
                          <MenuItem
                            value={response.name}
                            key={response.id}
                            onClick={() => handleChangeCity(response)}
                          >
                            {`${
                              response.type === "Kabupaten"
                                ? response.type.slice(0, 3) + "."
                                : response.type
                            } ${response.name}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.fieldSubdistrict}
                >
                  <Select
                    id="demo-simple-select-outlined"
                    value={
                      currentSubdistrict !== null && currentSubdistrict.name
                    }
                    style={{ width: 200 }}
                  >
                    {listSubdistrict !== null &&
                      listSubdistrict.map((response) => {
                        return (
                          <MenuItem
                            value={response.name}
                            key={response.id}
                            onClick={() => setCurrentSubdistrict(response)}
                          >
                            {response.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Popover>
          <div className={classes.toCenter}>
            <p className={classes.from} style={{ marginLeft: 30 }}>
              Ongkos Kirim&nbsp;
            </p>
            <div
              className={classes.toCenter}
              style={{ cursor: "pointer" }}
              onClick={handleClickPopoverCourier}
            >
              <Typography className={classes.bold}>
                Mulai dari Rp{minOngkir}
              </Typography>
              <ExpandMoreIcon style={{ height: 20 }} />
            </div>
          </div>
          <Popover
            id={idCourier}
            open={openCourier}
            anchorEl={anchorElCourier}
            onClose={handleCloseCourier}
            anchorOrigin={{ vartical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <div className={classes.containerPopoverCourier}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Pilih Kurir
                </InputLabel>
                {/* <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // value={currentCourier}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  label="Pilih Kurir"
                  onChange={handleChangeCourier}
                >
                  {supportedCourier &&
                    (isStoreFront
                      ? supportedCourier
                      : dataSupplier.supported_couriers
                    ).map((res, index) => {
                      return (
                        <MenuItem value={res} key={index}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography style={{ width: "80%" }}>
                              {res.name}
                            </Typography>
                            <img src={res.icon_url} alt="" />
                          </div>
                        </MenuItem>
                      );
                    })}
                </Select> */}
              </FormControl>
              <Grid container spacing={3} style={{ marginTop: 0 }}>
                {listOngkir &&
                  listOngkir[0].costs.map((res, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12}>
                          <div className={classes.courier}>
                            <Typography>
                              {res.description} ({res.service})
                            </Typography>
                            <Typography>Rp{res.cost[0].value}</Typography>
                          </div>
                          <div>
                            <Typography variant="caption">
                              Lama Pengiriman{" "}
                              {res.cost[0].etd.length === 0
                                ? '"tidak diketahui"'
                                : res.cost[0].etd 
                                //+
                                  //(currentCourier.code === "pos"
                                    //? ""
                                   // : " Hari")
                                  }
                            </Typography>
                          </div>
                        </Grid>
                      </Fragment>
                    );
                  })}
              </Grid>
            </div>
          </Popover>
        </div>
      </div>
    </Fragment>
  );
};

export default CekOngkir;

import React, { useState, useEffect } from "react";
import swal from "@sweetalert/with-react";
import isEmpty from "lodash/isEmpty";

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";

import {
  getProvinces,
  getCitiesByProvinceId,
  getDistrictsByCityId,
  getSubdistrictByDistrictId,
} from "../../services/location.service";
import {
  addCustomer,
  updateCustomer,
  getCustomers,
} from "../../services/customer.service";

const CustomerForm = ({ submitCallback, defaultCustomer }) => {
  const [listProvince, setListProvince] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listSubdistrict, setListSubdistrict] = useState([]);
  const [dataCustomer, setDataCustomer] = useState({
    name: "",
    address: "",
    province: "",
    city: "",
    subdistrict: "",
    district: "",
    postal_code: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (defaultCustomer) handleInitalData();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await getProvinces();
      setListProvince(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    };
    fetch();
  }, []);

  const handleInitalData = async () => {
    if (defaultCustomer) {
      if (defaultCustomer.village) {
        if (defaultCustomer.village.district) {
          const res = await getSubdistrictByDistrictId(
            defaultCustomer.village.district.id
          );
          setListSubdistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));

          if (defaultCustomer.village.district.city) {
            const res = await getDistrictsByCityId(
              defaultCustomer.village.district.city.id
            );
            setListDistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));

            if (defaultCustomer.village.district.city.province) {
              const res = await getCitiesByProvinceId(
                defaultCustomer.village.district.city.province.id
              );
              setListCity(res.data.sort((a, b) => a.name.localeCompare(b.name)));

              setDataCustomer({
                ...dataCustomer,
                name: defaultCustomer.name,
                address: defaultCustomer.address,
                province: defaultCustomer.village.district.city.province,
                city: defaultCustomer.village.district.city,
                subdistrict: defaultCustomer.village,
                district: defaultCustomer.village.district,
                postal_code: defaultCustomer.postal_code,
                phone: defaultCustomer.phone,
              });
            }
          }
        }
      }
    }
  };

  const handleChangeProvince = async (event) => {
    const res = await getCitiesByProvinceId(event.id);
    setListCity(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setDataCustomer({ ...dataCustomer, province: event });
  };

  const handleChangeCity = async (event) => {
    const res = await getDistrictsByCityId(event.id);
    setListDistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setDataCustomer({ ...dataCustomer, city: event });
  };

  const handleChangeDistrict = async (event) => {
    const res = await getSubdistrictByDistrictId(event.id);
    setListSubdistrict(res.data.sort((a, b) => a.name.localeCompare(b.name)));
    setDataCustomer({ ...dataCustomer, district: event });
  };

  const handleChangeSubdistrict = (event) => {
    setDataCustomer({
      ...dataCustomer,
      subdistrict: event,
      postal_code: event.kodepos || "",
    });
  };

  const handleAddcustomer = async () => {
    if (isEmpty(dataCustomer.name)) {
      return swal("Error!", "Nama wajib diisi", "error");
    } else if (isEmpty(dataCustomer.address)) {
      return swal("Error!", "Alamat wajib diisi", "error");
    } else if (isEmpty(dataCustomer.province)) {
      return swal("Error!", "Provinsi wajib diisi", "error");
    } else if (isEmpty(dataCustomer.city)) {
      return swal("Error!", "Kota wajib diisi", "error");
    } else if (isEmpty(dataCustomer.subdistrict)) {
      return swal("Error!", "Desa/Kelurahan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.district)) {
      return swal("Error!", "Kecamatan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.phone)) {
      return swal("Error!", "No.Telpon wajib diisi", "error");
    } else if (isEmpty(dataCustomer.postal_code)) {
      return swal("Error!", "Kodepos wajib diisi", "error");
    }

    try {
      setIsLoading(true);
      await addCustomer({
        name: dataCustomer.name,
        phone: dataCustomer.phone,
        address: dataCustomer.address,
        postal_code: dataCustomer.postal_code,
        village_id: dataCustomer.subdistrict.id,
      });
      swal("Success", "Berhasil Menambahkan Customer", "success");

      if (submitCallback) {
        submitCallback();
      }
    } catch (error) {
      return swal("Error!", "Terjadi kesalahan silahkan coba lagi", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCustomer = async () => {
    if (isEmpty(dataCustomer.province)) {
      return swal("Error!", "Provinsi wajib diisi", "error");
    } else if (isEmpty(dataCustomer.city)) {
      return swal("Error!", "Kota wajib diisi", "error");
    } else if (isEmpty(dataCustomer.subdistrict)) {
      return swal("Error!", "Desa/Kelurahan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.district)) {
      return swal("Error!", "Kecamatan wajib diisi", "error");
    } else if (isEmpty(dataCustomer.postal_code)) {
      return swal("Error!", "Kodepos wajib diisi", "error");
    }

    try {
      setIsLoading(true);
      const res = await updateCustomer(
        {
          village_id: dataCustomer.subdistrict.id,
          _method: "PUT",
        },
        defaultCustomer.id
      );
      swal("Success", "Berhasil Update Customer", "success");

      await getCustomers();

      if (submitCallback) {
        submitCallback(res.data);
      }
    } catch (error) {
      return swal("Error!", "Terjadi kesalahan silahkan coba lagi", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!defaultCustomer && (
        <>
          <TextField
            id="outlined-basic"
            style={{ marginTop: 20 }}
            value={dataCustomer.name}
            label="Nama Customer"
            variant="outlined"
            fullWidth={true}
            onChange={(e) =>
              setDataCustomer({ ...dataCustomer, name: e.target.value })
            }
          />
          <TextField
            id="outlined-basic"
            style={{ marginTop: 20 }}
            label="Alamat Customer"
            value={dataCustomer.address}
            variant="outlined"
            fullWidth={true}
            onChange={(e) =>
              setDataCustomer({ ...dataCustomer, address: e.target.value })
            }
          />
          <TextField
            id="outlined-basic"
            style={{ marginTop: 20 }}
            label="No.Telpon Customer"
            value={dataCustomer.phone}
            variant="outlined"
            fullWidth={true}
            onChange={(e) =>
              setDataCustomer({ ...dataCustomer, phone: e.target.value })
            }
          />
        </>
      )}

      <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
        <InputLabel id="demo-simple-select-outlined-label">Provinsi</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={
            dataCustomer.province.length === 0 ? "" : dataCustomer.province.name
          }
          label="Provinsi"
        >
          {listProvince !== null &&
            listProvince.map((res) => {
              return (
                <MenuItem
                  key={res.id}
                  value={res.name}
                  onClick={() => handleChangeProvince(res)}
                >
                  {res.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
        <InputLabel id="demo-simple-select-outlined-label">Kota</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={dataCustomer.city.length === 0 ? "" : dataCustomer.city.name}
          label="Kota"
        >
          {listCity !== null &&
            listCity.map((res) => {
              return (
                <MenuItem
                  key={res.id}
                  value={res.name}
                  onClick={() => handleChangeCity(res)}
                >
                  {res.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Kecamatan
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={
            dataCustomer.district.length === 0 ? "" : dataCustomer.district.name
          }
          label="Kecamatan"
        >
          {listDistrict.map((res) => {
            return (
              <MenuItem
                key={res.id}
                value={res.name}
                onClick={() => handleChangeDistrict(res)}
              >
                {res.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" style={{ width: "100%", marginTop: 20 }}>
        <InputLabel id="demo-simple-select-outlined-label">
          Desa/Kelurahan
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={
            dataCustomer.subdistrict.length === 0
              ? ""
              : dataCustomer.subdistrict.name
          }
          label="Desa/Kelurahan"
        >
          {listSubdistrict.map((res) => {
            return (
              <MenuItem
                key={res.id}
                value={res.name}
                onClick={() => handleChangeSubdistrict(res)}
              >
                {res.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" style={{ width: "100%" }}>
        <TextField
          id="outlined-basic"
          style={{ marginTop: 20 }}
          value={dataCustomer.postal_code}
          label="Kode Pos"
          type="number"
          variant="outlined"
          fullWidth={true}
          onChange={(e) =>
            setDataCustomer({ ...dataCustomer, postal_code: e.target.value })
          }
        />
      </FormControl>

      <Button
        style={{ width: "100%", margin: "20px 0" }}
        autoFocus
        variant="contained"
        color="primary"
        onClick={() =>
          defaultCustomer ? handleUpdateCustomer() : handleAddcustomer()
        }
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Submit"}
      </Button>
    </>
  );
};

export default CustomerForm;
